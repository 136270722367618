import { createSlice } from "@reduxjs/toolkit";

const initialCustomersState = {
  listLoading: false,
  notifications: null,
  totalNotifications: 0,

  typeNotifications: null,
  totalTypeNotifications: 0,

  operationalNotifications: null,
  totalOperationalNotifications: 0,

  operationTypeNotifications: null,
  totalOperationTypeNotifications: 0,

  checkpoints: null,
  totalCheckpoints: 0,

  schedule: null,
  totalSchedule: 0,

  patrolSchedule: null,
  totalPatrolSchedule: 0,


  patrolEntry: null,
  totalPatrolEntry: 0,

  patrolScheduleEntry: null,
  totalPatrolScheduleEntry: 0,

  items: null,
  totalItems: 0



};
export const callTypes = {
  list: "list",
  action: "action"
};

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState: initialCustomersState,
  reducers: {

    notificationsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.notifications = entities;
      state.totalNotifications = totalCount;
    },
    typeNotificationsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.typeNotifications = entities;
      state.totalTypeNotifications = totalCount;
    },
    operationalNotificationsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.operationalNotifications = entities;
      state.totalOperationalNotifications = totalCount;
    },
    operationTypeNotificationsFetched: (state, action) => {
      const { meta, entities } = action.payload;
      state.listLoading = false;
      state.operationTypeNotifications = entities;
      state.totalOperationTypeNotifications = meta;
    },
    CheckpointsFetched: (state, action) => {
      const { meta, entities } = action.payload;

      // let points = [];
      entities.forEach(point => {
        // let p = { ...entities, label: point.name }
        point.label = point.name;  
        // points.push(p);
      });
      // state.patrolSchedule = points;
      state.listLoading = false;
      state.checkpoints = entities;
      state.totalCheckpoints = meta;
    },
    schedulesFetched: (state, action) => {
      const { meta, entities } = action.payload;
      state.listLoading = false;
      state.schedule = entities;
      state.totalSchedule = meta;
    },
    patrolSchedulesFetched: (state, action) => {
      const { meta, entities } = action.payload;
      state.listLoading = false;
      state.patrolSchedule = entities;
      state.totalPatrolSchedule = meta;
    },

    patrolEntryFetched: (state, action) => {
      const { meta, entities } = action.payload;
      state.listLoading = false;
      state.patrolEntry = entities;
      state.totalpatrolEntry= meta;
    },

    patrolScheduleEntryFetched: (state, action) => {
      const { meta, entities } = action.payload;
      state.listLoading = false;
      state.patrolScheduleEntry = entities;
      state.totalPatrolScheduleEntry= meta;
    },

    
    ItemFetched: (state, action) => {
      const { meta, entities } = action.payload;
      state.listLoading = false;
      state.items = entities;
      state.totalItems= meta;
    }

  }
});
