// import React from "react"
// function Permission(){
//     return(<>Permission</>)
// }
// export default Permission
import React, { Component } from "react";
import { withRouter } from "react-router";
import $ from "jquery";
import axios from "axios";
import _ from "lodash";
import isEmpty from "../../../../Validations/is-empty";
import { Form, Table, Alert } from "react-bootstrap";
import { toast } from "react-toastify";
import ReactToPrint from "react-to-print";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import SearchForTable from "../../../Component/SearchForTable";

import { Menu, Button, MenuItem, Checkbox } from "@material-ui/core";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import UnfoldMoreIcon from "@material-ui/icons/UnfoldMore";

import SettingsIcon from "@material-ui/icons/Settings";
import VisibilityIcon from "@material-ui/icons/Visibility";
import jsPDF from "jspdf";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import PictureAsPdfOutlinedIcon from "@material-ui/icons/PictureAsPdfOutlined";

import "jspdf-autotable";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
var URL = process.env.REACT_APP_API_URL;
class Permission extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      permissionArray: [],
      modal_title: "Add Role Type",
      button_title: "Add",
      action: "Save",
      name: "",
      data: [],
      id: 0,
      pageNo: "1",
      itemsPerPage: "10",
      maxSize: 0,
      totalPages: "0",
      searchText: "",
      totalItems: 0,
      btn1Text: 1,
      btn2Text: 2,
      btn3Text: 3,
      btn4Text: 4,
      btn5Text: 5,
      showBtn1: false,
      showBtn2: false,
      showBtn3: false,
      showBtn4: false,
      showBtn5: false,
      btnFirstDisable: true,
      btnPreviousDisable: true,
      btnNextDisable: true,
      btnLastDisable: true,
      anchorEl: null,
      anchorEls: null,
      list: ["sr#", "name", "events"],
      show: ["sr#", "name", "events"],
      select: ["none"],
    };
    this.Save = this.Save.bind(this);
    this.onChange = this.onChange.bind(this);
    this.PaginateData = this.PaginateData.bind(this);
    this.GetTotalPages = this.GetTotalPages.bind(this);
    this.GetPaginatedData = this.GetPaginatedData.bind(this);
    this.GetPageData = this.GetPageData.bind(this);
    this.PreviousPageData = this.PreviousPageData.bind(this);
    this.NextPageData = this.NextPageData.bind(this);
    this.SetButtons = this.SetButtons.bind(this);
    this.Search = this.Search.bind(this);
    this.CheckKey.bind(this);
  }

  PaginateData(PageNo) {
    this.setState({ pageNo: PageNo + "" }, () => {
      axios
        .get(
          `${URL}/role?page=${this.state.pageNo}&limit=${this.state.itemsPerPage}`
        )
        .then((res) => {
          console.log(res.data);
          this.setState(
            {
              data: res.data.data,
              totalPages: Math.ceil(
                res.data.meta.total / res.data.meta.per_page
              ),
              totalItems: res.data.meta.total,
            },
            () => {
              this.SetButtons();
            }
          );
        });
    });
  }

  async GetTotalPages() {
    console.log(
      `${URL}/role?page=${this.state.pageNo}&limit=${this.state.itemsPerPage}`
    );
    await axios
      .get(
        `${URL}/role?page=${this.state.pageNo}&limit=${this.state.itemsPerPage}`
      )
      .then((res) => {
        console.log(res.data);
        this.setState(
          {
            data: res.data.data,
            totalPages: Math.ceil(res.data.meta.total / res.data.meta.per_page),
            totalItems: res.data.meta.total,
          },
          () => {
            this.SetButtons();
          }
        );
      })
      .catch((err) => {
        console.log(err);
      });
    let array = [];
    if (JSON.parse(localStorage.getItem("permission"))) {
      JSON.parse(localStorage.getItem("permission")).map(async (v) => {
        await array.push(v.name);
      });
    }
    this.setState({
      permissionArray: array,
    });
  }

  GetPaginatedData = () => {
    axios
      .get(
        `${URL}/role?page=${this.state.pageNo}&limit=${this.state.itemsPerPage}`
      )
      .get((res) => {
        console.log(res.data);
        this.setState(
          {
            data: res.data.data,
            totalPages: Math.ceil(res.data.meta.total / res.data.meta.per_page),
            totalItems: res.data.meta.total,
          },
          () => {
            this.SetButtons();
          }
        );
      });
  };

  GetPageData = (id) => {
    var data = {
      itemsPerPage: this.state.itemsPerPage,
      search: this.state.searchText,
      pageNo: this.state.pageNo,
    };
    if (id === "1") {
      this.setState({ pageNo: $("#bnt1").text() }, () => {
        data.pageNo = this.state.pageNo;
      });
    } else if (id === "2") {
      this.setState({ pageNo: $("#bnt2").text() }, () => {
        data.pageNo = this.state.pageNo;
      });
    } else if (id === "3") {
      this.setState({ pageNo: $("#bnt3").text() }, () => {
        data.pageNo = this.state.pageNo;
      });
    } else if (id === "4") {
      this.setState({ pageNo: $("#bnt4").text() }, () => {
        data.pageNo = this.state.pageNo;
      });
    } else if (id === "5") {
      this.setState({ pageNo: $("#bnt5").text() }, () => {
        data.pageNo = this.state.pageNo;
      });
    }
    axios
      .get(`${URL}/role?page=${id}&limit=${this.state.itemsPerPage}`)
      .then(async (res) => {
        console.log(res.data);
        this.setState(
          {
            data: res.data.data,
            totalPages: Math.ceil(res.data.meta.total / res.data.meta.per_page),
            totalItems: res.data.meta.total,
          },
          () => {
            this.SetButtons();
          }
        );
      });
  };

  PreviousPageData = () => {
    if (this.state.pageNo !== 1) {
      this.setState({ pageNo: Number(this.state.pageNo) - 1 + "" }, () => {
        axios
          .get(
            `${URL}/role?page=${this.state.pageNo}&limit=${this.state.itemsPerPage}`
          )
          .then(async (res) => {
            console.log(res.data);
            this.setState(
              {
                data: res.data.data,
                totalPages: Math.ceil(
                  res.data.meta.total / res.data.meta.per_page
                ),
                totalItems: res.data.meta.total,
              },
              () => {
                this.SetButtons();
              }
            );
          });
      });
    }
  };

  NextPageData = () => {
    console.log("Getting Next Page");
    this.setState({ pageNo: Number(this.state.pageNo) + 1 + "" }, () => {
      axios
        .get(
          `${URL}/role/?page=${Number(this.state.pageNo)}&limit=${Number(
            this.state.itemsPerPage
          )}`
        )
        .then(async (res) => {
          console.log(res.data);
          this.setState(
            {
              data: res.data.data,
              totalPages: Math.ceil(
                res.data.meta.total / res.data.meta.per_page
              ),
              totalItems: res.data.meta.total,
            },
            () => {
              this.SetButtons();
            }
          );
        })
        .catch((err) => {
          console.log(err.response);
        });
    });
  };

  SetButtons = () => {
    if (parseInt(this.state.pageNo) === 1) {
      $("#btnFirst").prop("disabled", true);
      $("#btnPrevious").prop("disabled", true);
      $("#btnNext").prop("disabled", false);
      $("#btnLast").prop("disabled", false);
      $("#bnt1").removeClass("btn-outline-primary");
      $("#bnt1").addClass("btn-primary");
      $("#bnt2").removeClass("btn-primary");
      $("#bnt2").addClass("btn-outline-primary");
      $("#bnt4").removeClass("btn-primary");
      $("#bnt4").addClass("btn-outline-primary");
      $("#bnt3").removeClass("btn-primary");
      $("#bnt3").addClass("btn-outline-primary");
      $("#bnt5").removeClass("btn-primary");
      $("#bnt5").addClass("btn-outline-primary");
      $("#bnt1").html("1");
      $("#bnt2").html("2");
      $("#bnt3").html("3");
      $("#bnt4").html("4");
      $("#bnt5").html("5");
      if (parseInt(this.state.totalPages) === 1) {
        $("#btnNext").prop("disabled", true);
        $("#btnLast").prop("disabled", true);
      }
    } else if (
      parseInt(this.state.pageNo) === parseInt(this.state.totalPages)
    ) {
      $("#btnFirst").prop("disabled", false);
      $("#btnPrevious").prop("disabled", false);
      $("#btnNext").prop("disabled", true);
      $("#btnLast").prop("disabled", true);
      if (parseInt(this.state.totalPages) < 6) {
        $("#bnt" + this.state.totalPages).removeClass("btn-outline-primary");
        $("#bnt" + this.state.totalPages).addClass("btn-primary");
        for (var i = parseInt(this.state.totalPages) - 1; i > 0; i--) {
          $("#bnt" + i).removeClass("btn-primary");
          $("#bnt" + i).addClass("btn-outline-primary");
        }
      } else {
        $("#bnt5").removeClass("btn-outline-primary");
        $("#bnt5").addClass("btn-primary");
        $("#bnt4").removeClass("btn-primary");
        $("#bnt4").addClass("btn-outline-primary");
        $("#bnt3").removeClass("btn-primary");
        $("#bnt3").addClass("btn-outline-primary");
        $("#bnt2").removeClass("btn-primary");
        $("#bnt2").addClass("btn-outline-primary");
        $("#bnt1").removeClass("btn-primary");
        $("#bnt1").addClass("btn-outline-primary");

        $("#bnt1").html("" + (parseInt(this.state.pageNo) - 4));
        $("#bnt2").html("" + (parseInt(this.state.pageNo) - 3));
        $("#bnt3").html("" + (parseInt(this.state.pageNo) - 2));
        $("#bnt4").html("" + (parseInt(this.state.pageNo) - 1));
        $("#bnt5").html("" + parseInt(this.state.pageNo));
      }
    } else {
      $("#btnFirst").prop("disabled", false);
      $("#btnPrevious").prop("disabled", false);
      $("#btnNext").prop("disabled", false);
      $("#btnLast").prop("disabled", false);
      if (parseInt(this.state.totalPages) < 6) {
        for (var j = parseInt(this.state.totalPages); j > 0; j--) {
          $("#bnt" + j).removeClass("btn-primary");
          $("#bnt" + j).addClass("btn-outline-primary");
        }
        $("#bnt" + this.state.pageNo).removeClass("btn-outline-primary");
        $("#bnt" + this.state.pageNo).addClass("btn-primary");
      } else {
        if (parseInt(this.state.pageNo) === 2) {
          $("#bnt5").addClass("btn-outline-primary");
          $("#bnt5").removeClass("btn-primary");
          $("#bnt4").removeClass("btn-primary");
          $("#bnt4").addClass("btn-outline-primary");
          $("#bnt3").removeClass("btn-primary");
          $("#bnt3").addClass("btn-outline-primary");
          $("#bnt2").removeClass("btn-outline-primary");
          $("#bnt2").addClass("btn-primary");
          $("#bnt1").removeClass("btn-primary");
          $("#bnt1").addClass("btn-outline-primary");
        } else if (parseInt(this.state.pageNo) === 3) {
          $("#bnt1").html("1");
          $("#bnt2").html("2");
          $("#bnt3").html("3");
          $("#bnt4").html("4");
          $("#bnt5").html("5");
          $("#bnt5").addClass("btn-outline-primary");
          $("#bnt5").removeClass("btn-primary");
          $("#bnt4").removeClass("btn-primary");
          $("#bnt4").addClass("btn-outline-primary");
          $("#bnt2").removeClass("btn-primary");
          $("#bnt2").addClass("btn-outline-primary");
          $("#bnt3").removeClass("btn-outline-primary");
          $("#bnt3").addClass("btn-primary");
          $("#bnt1").removeClass("btn-primary");
          $("#bnt1").addClass("btn-outline-primary");
        } else {
          if (
            parseInt(this.state.totalPages) - parseInt(this.state.pageNo) >
            2
          ) {
            $("#bnt1").html("" + (parseInt(this.state.pageNo) - 2));
            $("#bnt2").html("" + (parseInt(this.state.pageNo) - 1));
            $("#bnt3").html("" + parseInt(this.state.pageNo));
            $("#bnt4").html("" + (parseInt(this.state.pageNo) + 1));
            $("#bnt5").html("" + (parseInt(this.state.pageNo) + 2));
          } else if (
            parseInt(this.state.totalPages) - parseInt(this.state.pageNo) >
            1
          ) {
            $("#bnt1").html("" + (parseInt(this.state.pageNo) - 2));
            $("#bnt2").html("" + (parseInt(this.state.pageNo) - 1));
            $("#bnt3").html("" + parseInt(this.state.pageNo));
            $("#bnt4").html("" + (parseInt(this.state.pageNo) + 1));
            $("#bnt5").html("" + (parseInt(this.state.pageNo) + 2));
            $("#bnt5").addClass("btn-outline-primary");
            $("#bnt5").removeClass("btn-primary");
            $("#bnt4").removeClass("btn-primary");
            $("#bnt4").addClass("btn-outline-primary");
            $("#bnt2").removeClass("btn-outline-primary");
            $("#bnt2").addClass("btn-outline-primary");
            $("#bnt3").removeClass("btn-outline-primary");
            $("#bnt3").addClass("btn-primary");
            $("#bnt1").removeClass("btn-primary");
            $("#bnt1").addClass("btn-outline-primary");
          } else if (
            parseInt(this.state.totalPages) - parseInt(this.state.pageNo) ===
            1
          ) {
            $("#bnt1").html("" + (parseInt(this.state.pageNo) - 3));
            $("#bnt2").html("" + (parseInt(this.state.pageNo) - 2));
            $("#bnt3").html("" + (parseInt(this.state.pageNo) - 1));
            $("#bnt4").html("" + parseInt(this.state.pageNo));
            $("#bnt5").html("" + (parseInt(this.state.pageNo) + 1));
            $("#bnt5").addClass("btn-outline-primary");
            $("#bnt5").removeClass("btn-primary");
            $("#bnt3").removeClass("btn-primary");
            $("#bnt3").addClass("btn-outline-primary");
            $("#bnt2").removeClass("btn-outline-primary");
            $("#bnt2").addClass("btn-outline-primary");
            $("#bnt4").removeClass("btn-outline-primary");
            $("#bnt4").addClass("btn-primary");
            $("#bnt1").removeClass("btn-primary");
            $("#bnt1").addClass("btn-outline-primary");
          }
        }
      }
    }
  };
  handleClick = (path, b) => {
    const char = this.state.data;
    const ch = _.orderBy(char, [path], [this.state.sort ? "asc" : "desc"]);
    this.setState({ data: [] });
    this.setState({ data: ch });
    this.setState({ sort: !this.state.sort });
    this.setState({ select: [b] });
  };

  Search = (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      this.GetTotalPages();
    }
  };

  CheckKey = (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      this.Save();
    }
  };
  handleDeleteALert = (id) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>Are you sure?</h1>
            <p>You want to delete this?</p>
            <button
              className="btn btn-danger"
              onClick={onClose}
              style={{ marginRight: 10 }}
            >
              No
            </button>
            <button
              className="btn btn-primary"
              onClick={() => {
                this.Delete(id);
                onClose();
              }}
            >
              Yes, Delete it!
            </button>
          </div>
        );
      },
    });
  };
  Delete = (id) => {
    this.setState({
      msg: "Deleting type...",
      error: "",
    });
    axios
      .delete(`${URL}/role/${id}`)
      .then(async (res) => {
        toast.success("Delete Succesfully!");
        this.setState(
          {
            msg: res.data.message,
            error: "",
            id: "",
            name: "",
            action: "Save",
            modal_title: "Add Role",
            button_title: "Add",
          },
          () => {
            this.GetTotalPages();
          }
        );
      })
      .catch((err) => {
        console.log(err.response);
        toast.error(err?.response?.data?.errors?.message);
      });
  };

  UpdateData = (obj) => {
    this.setState({
      id: obj.id,
      name: obj.name,
      modal_title: "Update Role",
      button_title: "Update",
      action: "Update",
    });
  };

  AddData = () => {
    this.setState({
      id: 0,
      name: "",
      modal_title: "Add Role",
      button_title: "Add",
      action: "Save",
    });
  };

  componentWillMount() {
    this.GetTotalPages();
    console.log(process.env.REACT_APP_API_URL);
  }
  onSubmitValue = (val) => {
    console.log(val);
    this.setState({ search: val ? val : "" });
    axios
      .get(
        `${URL}/role?page=${this.state.pageNo}&limit=${this.state.itemsPerPage}&search=${val}`
      )
      .then(async (res) => {
        console.log(res.data);
        this.setState(
          {
            data: res.data.data,
            totalPages: Math.ceil(res.data.meta.total / res.data.meta.per_page),
            totalItems: res.data.meta.total,
          },
          () => {
            this.SetButtons();
          }
        );
      });
  };
  async Save() {
    if (!isEmpty(this.state.name)) {
      if (this.state.action === "Save") {
        this.setState({
          msg: "Adding type...",
          error: "",
        });
        const data = { name: this.state.name, permissions: [1] };
        axios
          .post(`${URL}/role`, data)
          .then(async (res) => {
            toast.success("Roles Saved Successfully!");
            this.setState(
              {
                msg: "Type Saved Successfully!",
                error: "",
                id: "",
                name: "",
                action: "Save",
                modal_title: "Add Role",
                button_title: "Add",
              },
              () => {
                this.GetTotalPages();
              }
            );
          })
          .catch((err) => {
            if (err.response.data.errors[0].rule === "unique") {
              toast.error("Role with same name already exists!");
            } else {
              toast.error(err.response.data.errors[0].message);
            }
          });
      } else {
        this.setState({
          msg: "Updating type...",
          error: "",
        });
        const data = { id: this.state.id, name: this.state.name };
        axios
          .put(`${URL}/role/${this.state.id}`, data)
          .then(async (res) => {
            toast.success("Roles Updated Successfully!");
            this.setState(
              {
                msg: "Roles Updated Successfully!",
                error: "",
                id: "",
                name: "",
                action: "Save",
                modal_title: "Add Type",
                button_title: "Add",
              },
              () => {
                this.GetTotalPages();
              }
            );
          })
          .catch((err) => {
            if (err.response.data.errors[0].rule === "unique") {
              toast.error("Role with same name already exists!");
            } else {
              toast.error(err.response.data.errors[0].message);
            }
          });
      }
    } else {
      toast.error("Please enter a name first!");
      this.setState({
        msg: "",
        error: "Please enter a name first!",
      });
    }
  }

  onChange(e) {
    var name = e.target.name;
    console.log(e.target.value);
    this.setState({ [e.target.name]: e.target.value }, () => {
      console.log(this.state.itemsPerPage);
      if (name === "itemsPerPage") {
        this.GetTotalPages();
      }
    });
  }
  exportPDF = () => {
    const unit = "pt";
    const size = "A4";
    const orientation = "portrait";

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Role";
    const headers = [["sr#", "name"]];

    const data = this.state.data.map((obj, k) => [k + 1, obj.name]);
    console.log(data, "data from export");

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("Roles.pdf");
  };
  showClick = (v) => {
    let newArray = [];
    if (this.state.show.includes(v)) {
      newArray = this.state.show.filter((e) => e !== v);
      this.setState({ show: newArray });
    } else {
      newArray = this.state.show;
      newArray.push(v);
      this.setState({ show: newArray });
    }
  };

  render() {
    const handleClicks = (event) => {
      this.setState({ anchorEl: event.currentTarget });
    };
    const handleClicks1 = (event) => {
      this.setState({ anchorEls: event.currentTarget });
    };
    const handleClose = () => {
      this.setState({ anchorEl: null });
    };
    const handleCloses = () => {
      this.setState({ anchorEls: null });
    };
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const exportToCSV = (csvData, fileName) => {
      let d = [];
      csvData.map((v, k) =>
        d.push({
          sr: k + 1,
          name: v.name,
        })
      );

      const ws = XLSX.utils.json_to_sheet(d);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    };
    return (
      <React.Fragment>
        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
            marginBottom: "20px",
          }}
        >
          {console.log(this.state.permissionArray, "find")}

          <span
            className="flaticon-arrested mr-3 mb-1"
            style={{ fontSize: "35px", color: "#23303D" }}
          ></span>
          <h3 id="modal" style={{ marginBottom: "15px" }}>
            <span style={{ marginBottom: "15px" }}>Role Types</span>
          </h3>
        </div>
        <div className="page-content">
          <div className="row">
            <div className="col-sm-6 col-md-8 col-lg-8"></div>
            <div
              className="col-sm-6 col-md-4 col-lg-4"
              style={{ float: "right" }}
            ></div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-4">
              <Card>
                <CardHeader title={this.state.modal_title}></CardHeader>
                <CardBody>
                  <Form.Group>
                    <Form.Label>Role Type</Form.Label>
                    <div class="input-group ">
                      <input
                        onKeyPress={this.CheckKey}
                        className="form-control form-control-sm"
                        type="text"
                        placeholder="Enter Type"
                        value={this.state.name}
                        name="name"
                        onChange={this.onChange}
                      ></input>
                      <div class="input-group-prepend">
                        <span
                          class="input-group-text"
                          id="basic-addon1"
                          style={{ padding: "5px" }}
                        >
                          <span
                            className="flaticon-arrested "
                            style={{
                              fontSize: "12.5px",
                            }}
                          ></span>
                          {/*"add icon for feild here "*/}
                        </span>
                      </div>
                    </div>
                  </Form.Group>

                  <button
                    className="btn btn-primary"
                    onClick={() => this.Save()}
                    style={{ float: "right" }}
                  >
                    {this.state.button_title}
                  </button>

                  <button
                    className="btn btn-danger"
                    onClick={() => this.AddData()}
                    name="cancel"
                    style={{ float: "left" }}
                  >
                    Cancel
                  </button>
                </CardBody>
              </Card>
            </div>

            <div className="col-sm-12 col-md-6 col-lg-8">
              <Card>
                <CardHeader title="Role Types">
                  <CardHeaderToolbar>
                    <Button
                      color="secondary"
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      onClick={handleClicks}
                    >
                      <VisibilityIcon />
                    </Button>
                    <Menu
                      id="simple-menu"
                      anchorEl={this.state.anchorEl}
                      keepMounted
                      open={Boolean(this.state.anchorEl)}
                      onClose={handleClose}
                    >
                      {this.state.list.map((v, k) => (
                        <MenuItem
                          key={k}
                          style={{ textTransform: "capitalize" }}
                          onClick={() => this.showClick(v)}
                        >
                          <Checkbox checked={this.state.show.includes(v)} /> {v}
                        </MenuItem>
                      ))}
                    </Menu>
                    <Button
                      color="secondary"
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      onClick={handleClicks1}
                    >
                      <SettingsIcon />
                    </Button>
                    <Menu
                      id="simple-menu"
                      anchorEl={this.state.anchorEls}
                      keepMounted
                      open={Boolean(this.state.anchorEls)}
                      onClose={handleCloses}
                    >
                      <MenuItem
                        onClick={() => exportToCSV(this.state.data, "Role")}
                      >
                        Generate To excel{" "}
                        <InsertDriveFileIcon
                          className="ml-2"
                          style={{ color: "#AEAFAD" }}
                        />
                      </MenuItem>
                      <MenuItem onClick={() => this.exportPDF()}>
                        Report To Pdf{" "}
                        <PictureAsPdfOutlinedIcon
                          className="ml-2"
                          style={{ color: "#AEAFAD" }}
                        />{" "}
                      </MenuItem>
                    </Menu>
                    <ReactToPrint
                      trigger={() => {
                        return (
                          <button
                            type="button"
                            className="btn btn-outline-primary ml-3 mr-3"
                            onClick={this.print}
                          >
                            Print
                          </button>
                        );
                      }}
                      content={() => this.componentRef1}
                    />
                    {this.state.permissionArray.includes("Create Role") ? (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => this.AddData()}
                        name="add_button"
                      >
                        Add New Roles
                      </button>
                    ) : (
                      ""
                    )}
                  </CardHeaderToolbar>
                </CardHeader>
                <div>
                  <SearchForTable onSubmitValue={this.onSubmitValue} />
                </div>
                <CardBody ref={(el) => (this.componentRef1 = el)}>
                  <div className=" row  mb-5 print d-none">
                    <h4 className="text-center col-12">Roles List</h4>
                  </div>
                  <Table responsive>
                    <thead>
                      <tr>
                        {this.state.show.includes("sr#") ? (
                          <th
                            width="15%"
                            onClick={() => this.handleClick("id", "sr#")}
                          >
                            <span style={{ display: "flex" }}>
                              Sr#
                              <div
                                className="d-flex"
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <ExpandLessIcon
                                  style={{
                                    height: "16px",
                                    color:
                                      this.state.sort === false &&
                                      this.state.select.includes("sr#")
                                        ? "black"
                                        : "#898f8b",
                                    marginTop: "-3px",
                                  }}
                                />
                                <ExpandMoreIcon
                                  style={{
                                    height: "16px",
                                    color:
                                      this.state.sort === true &&
                                      this.state.select.includes("sr#")
                                        ? "black"
                                        : "#898f8b",
                                    marginTop: "-8px",
                                  }}
                                />
                              </div>
                            </span>
                          </th>
                        ) : null}
                        {this.state.show.includes("name") ? (
                          <th
                            width="70%"
                            onClick={() => this.handleClick("name", "name")}
                          >
                            <span style={{ display: "flex" }}>
                              {" "}
                              Name{" "}
                              <div
                                className="d-flex"
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <ExpandLessIcon
                                  style={{
                                    height: "16px",
                                    color:
                                      this.state.sort === false &&
                                      this.state.select.includes("name")
                                        ? "black"
                                        : "#898f8b",
                                    marginTop: "-3px",
                                  }}
                                />
                                <ExpandMoreIcon
                                  style={{
                                    height: "16px",
                                    color:
                                      this.state.sort === true &&
                                      this.state.select.includes("name")
                                        ? "black"
                                        : "#898f8b",
                                    marginTop: "-8px",
                                  }}
                                />
                              </div>
                            </span>
                          </th>
                        ) : null}
                        {this.state.show.includes("events") ? (
                          <th width="20%" className="text-center notPrint">
                            <span style={{ display: "flex" }}> Events </span>
                          </th>
                        ) : null}
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.data ? (
                        this.state.data.map((obj, k) => (
                          <tr>
                            {this.state.show.includes("sr#") ? (
                              <td>
                                {" "}
                                {this.state.itemsPerPage *
                                  (this.state.pageNo - 1) +
                                  k +
                                  1}
                              </td>
                            ) : null}
                            {this.state.show.includes("name") ? (
                              <td>{obj.name}</td>
                            ) : null}
                            {this.state.show.includes("events") ? (
                              <td class="text-center notPrint">
                                {this.state.permissionArray.includes(
                                  "Update Role"
                                )
                                  ? !!obj.user_id && (
                                      <button
                                        onClick={() => this.UpdateData(obj)}
                                        class="btn btn-xs btn-default btn-text-primary btn-hover-primary btn-icon btn-icon-primary mr-2"
                                        title="Edit details"
                                        ng-click="UpdateData(role)"
                                      >
                                        <span class="svg-icon svg-icon-md">
                                          <svg
                                            viewBox="0 0 24 24"
                                            version="1.1"
                                          >
                                            <g
                                              stroke="none"
                                              stroke-width="1"
                                              fill="none"
                                              fill-rule="evenodd"
                                            >
                                              <rect
                                                x="0"
                                                y="0"
                                                width="24"
                                                height="24"
                                              ></rect>
                                              <path
                                                d="M12.2674799,18.2323597 L12.0084872,5.45852451 C12.0004303,5.06114792 12.1504154,4.6768183 12.4255037,4.38993949 L15.0030167,1.70195304 L17.5910752,4.40093695 C17.8599071,4.6812911 18.0095067,5.05499603 18.0083938,5.44341307 L17.9718262,18.2062508 C17.9694575,19.0329966 17.2985816,19.701953 16.4718324,19.701953 L13.7671717,19.701953 C12.9505952,19.701953 12.2840328,19.0487684 12.2674799,18.2323597 Z"
                                                fill="#000000"
                                                fill-rule="nonzero"
                                                transform="translate(14.701953, 10.701953) rotate(-135.000000) translate(-14.701953, -10.701953) "
                                              ></path>
                                              <path
                                                d="M12.9,2 C13.4522847,2 13.9,2.44771525 13.9,3 C13.9,3.55228475 13.4522847,4 12.9,4 L6,4 C4.8954305,4 4,4.8954305 4,6 L4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 L2,6 C2,3.790861 3.790861,2 6,2 L12.9,2 Z"
                                                fill="#000000"
                                                fill-rule="nonzero"
                                                opacity="0.3"
                                              ></path>
                                            </g>
                                          </svg>
                                        </span>
                                      </button>
                                    )
                                  : ""}
                                {this.state.permissionArray.includes(
                                  "Delete Role"
                                )
                                  ? !!obj.user_id && (
                                      <button
                                        onClick={() =>
                                          this.handleDeleteALert(obj.id)
                                        }
                                        class="btn btn-xs btn-default btn-text-primary btn-hover-danger btn-icon btn-icon-danger "
                                        title="Delete"
                                        ng-click="Delete(role.srNo)"
                                      >
                                        <span class="svg-icon svg-icon-md">
                                          <svg
                                            viewBox="0 0 24 24"
                                            version="1.1"
                                          >
                                            <g
                                              stroke="none"
                                              stroke-width="1"
                                              fill="none"
                                              fill-rule="evenodd"
                                            >
                                              <rect
                                                x="0"
                                                y="0"
                                                width="24"
                                                height="24"
                                              ></rect>
                                              <path
                                                d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                                fill="#000000"
                                                fill-rule="nonzero"
                                              ></path>
                                              <path
                                                d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                                fill="#000000"
                                                opacity="0.3"
                                              ></path>
                                            </g>
                                          </svg>
                                        </span>
                                      </button>
                                    )
                                  : ""}
                              </td>
                            ) : null}{" "}
                          </tr>
                        ))
                      ) : (
                        <h3>Loading...</h3>
                      )}
                    </tbody>
                    <tfoot className="d-none">
                      <tr>
                        <td align="center" colSpan="3">
                          <div className="pull-right">
                            <button
                              id="btnFirst"
                              onClick={() => this.PaginateData(1)}
                              className="btn btn-rounded btn-sm btn-outline-primary mr-1"
                            >
                              First
                            </button>
                            <button
                              id="btnPrevious"
                              onClick={() => this.PreviousPageData()}
                              className="btn btn-rounded btn-sm btn-outline-primary mr-1"
                            >
                              Previous
                            </button>
                            {this.state.totalPages !== "0" ? (
                              <button
                                id="bnt1"
                                onClick={() => this.GetPageData("1")}
                                className="btn btn-rounded btn-sm btn-outline-primary mr-1"
                              >
                                1
                              </button>
                            ) : null}
                            {this.state.totalPages > "1" ? (
                              <button
                                id="bnt2"
                                onClick={() => this.GetPageData("2")}
                                className="btn btn-rounded btn-sm btn-outline-primary mr-1"
                              >
                                2
                              </button>
                            ) : null}
                            {this.state.totalPages > "2" ? (
                              <button
                                id="bnt3"
                                onClick={() => this.GetPageData("3")}
                                className="btn btn-rounded btn-sm btn-outline-primary mr-1"
                              >
                                3
                              </button>
                            ) : null}
                            {this.state.totalPages > "3" ? (
                              <button
                                id="bnt4"
                                onClick={() => this.GetPageData("4")}
                                className="btn btn-rounded btn-sm btn-outline-primary mr-1"
                              >
                                4
                              </button>
                            ) : null}
                            {this.state.totalPages > "4" ? (
                              <button
                                id="bnt5"
                                onClick={() => this.GetPageData("5")}
                                className="btn btn-rounded btn-sm btn-outline-primary mr-1"
                              >
                                5
                              </button>
                            ) : null}
                            <button
                              id="btnNext"
                              onClick={() => this.NextPageData()}
                              className="btn btn-rounded btn-sm btn-outline-primary mr-1"
                            >
                              Next
                            </button>
                            <button
                              id="btnLast"
                              onClick={() =>
                                this.PaginateData(this.state.totalPages)
                              }
                              className="btn btn-rounded btn-sm btn-outline-primary"
                            >
                              Last
                            </button>
                          </div>
                          <div className="row">
                            <div className="col-6">
                              <label className="btn btn-primary  mt-2 pulll-left">
                                {this.state.pageNo * this.state.itemsPerPage -
                                  this.state.itemsPerPage}{" "}
                                -{" "}
                                {this.state.pageNo === this.state.totalPages
                                  ? this.state.totalItems
                                  : this.state.pageNo *
                                    this.state.itemsPerPage}{" "}
                                / {this.state.totalItems} items
                              </label>
                            </div>
                            <div className="col-6">
                              <div className="row">
                                <div className="col-3">
                                  <select
                                    className="form-control form-control-sm mt-3"
                                    name="itemsPerPage"
                                    onChange={this.onChange}
                                  >
                                    <option value="20">20</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                    <option value="500">500</option>
                                  </select>
                                </div>
                                <div className="col-9">
                                  {" "}
                                  <button className="btn btn-primary mt-2 pull-right col-8">
                                    Page: {this.state.pageNo} /{" "}
                                    {this.state.totalPages}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tfoot>
                  </Table>
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(Permission);
