export const fetchByDotOperator = (object, value) => {
  return value.split(".").reduce((acc, curr) => acc[curr], object);
};
export const GetNestedObjectString = (array) => {
  let arrString = "";
  array.map((a, i) => {
    arrString = arrString + a + ".";
  });
  return arrString;
};
