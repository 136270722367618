import React from "react";
import { Button, MenuItem, Menu } from "@material-ui/core";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import SettingsIcon from "@material-ui/icons/Settings";

function ExportCsv({ csvData, fileName }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const exportToPdf = () => {
    const unit = "pt";
    const size = "A3";
    const orientation = "p";

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);
    let headers = [];
    let data = csvData;
    if (data.length > 0) {
      let finaldata = [];
      let head = Object.keys(data[0]);
      headers.push(head);
      data.map((obj) => {
        let sample = [];
        for (let i = 0; i < head.length; i++) {
          sample.push(obj[head[i]]);
        }
        finaldata.push(sample);
      });

      let content = {
        startY: 50,
        head: headers,
        body: finaldata,
      };
      doc.setFontSize(13);

      doc.text(fileName, marginLeft, 40);
      doc.autoTable(content);
      doc.save(fileName + ".pdf");
    }
  };
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const exportToCSV = () => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <>
      <Button color="secondary" onClick={handleClick} className="mt-5">
        <SettingsIcon />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={exportToCSV}>Export To CSV</MenuItem>
        <MenuItem onClick={exportToPdf}>Export To PDF</MenuItem>
      </Menu>
    </>
  );
}
export default ExportCsv;
