import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { DashboardPage } from "./pages/DashboardPage";
import Projects from "./modules/ECommerce/pages/Project/Projects";
import AddProject from "./modules/ECommerce/pages/Project/AddProject";
import UpdateProject from "./modules/ECommerce/pages/Project/UpdateProject";
import Staff from "./modules/ECommerce/pages/Staff/Staff";
import AddStaff from "./modules/ECommerce/pages/Staff/AddStaff";
import UpdateStaff from "./modules/ECommerce/pages/Staff/UpdateStaff";
import Roles from "./modules/ECommerce/pages/Staff/Role";
import Permission from "./modules/ECommerce/pages/Staff/Permission";

export default function BasePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {<Redirect exact from="/" to="/dashboard" />}
        <ContentRoute exact path="/dashboard" component={DashboardPage} />
        <Route exact path="/client/permission" component={Roles} />
        <Route exact path="/client/roles" component={Permission} />
        <Route exact path="/client/staff" component={Staff} />
        <Route exact path="/client/add-staff" component={AddStaff} />
        <Route exact path="/client/update-staff" component={UpdateStaff} />
        <Route exact path="/client/project" component={Projects} />
        <Route exact path="/client/add-project" component={AddProject} />
        <Route exact path="/client/update-project" component={UpdateProject} />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
