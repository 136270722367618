const CheckPermission = (permission_name) => {
  let array = [];
  if (JSON.parse(localStorage.getItem("permission"))) {
    JSON.parse(localStorage.getItem("permission")).map(async (v) => {
      await array.push(v.slug);
    });
    console.log(array);
    if (array.includes(permission_name)) return true;
    else return false;
  } else {
    return false;
  }
};

export default CheckPermission;
