import React, { Component } from "react";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import axios from "axios";
import isEmpty from "../../../../Validations/is-empty";
import { Form, InputGroup } from "react-bootstrap";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { Link } from "react-router-dom";
import { Avatar } from "@material-ui/core";
import Select from "react-select";

const URL = process.env.REACT_APP_API_URL;
const BASE_URL = process.env.REACT_APP_API_BASE_URL;
class AddStaff extends Component {
  constructor(props) {
    super(props);
    var currentDate = new Date();
    var dd = String(currentDate.getDate()).padStart(2, "0");
    var mm = String(currentDate.getMonth() + 1).padStart(2, "0");
    var yyyy = currentDate.getFullYear();
    currentDate = yyyy + "-" + mm + "-" + dd;
    this.state = {
      name: "",
      mobile_no: "",
      email: "",
      avatar: null,
      staff_code: "",
      nfc_code: "",
      username: "",
      country: "",
      city: "",
      state1: "",
      post_code: "",
      address: "",
      addditional_information: null,
      password: "",
      password_confirmation: "",
      role_id: "0",
      image: null,
      roles: [],
      allCountry: [],
      allState: [],
      allCity: [],
      staff_Id: "",
      msg: "",
      error: "",
      password_expiry: currentDate,
      imagePreview1: null,
      imagePreview2: null,
      show1: false,
      show2: false,
      projects: [],
      project_id: "0",
    };
    this.Save = this.Save.bind(this);
    this.onChange = this.onChange.bind(this);
    this.GetRoles = this.GetRoles.bind(this);
    this.GetCountry = this.GetCountry.bind(this);
    this.RoleChanges = this.RoleChanges.bind(this);
    this.UploadFile = this.UploadFile.bind(this);
  }

  CheckKey = (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      this.Save();
    }
  };

  GetRoles = () => {
    axios.get(`${URL}/role?page=1&limit=1000`).then(async (res) => {
      var newCategories = [{ id: "0", name: "-- Select Role --" }];
      for (var i = 0; i < res.data.data.length; i++) {
        newCategories.push({
          id: res.data.data[i].id + "",
          name: res.data.data[i].name,
        });
      }
      var final = newCategories.filter(
        (v) => v.name.toLowerCase() !== "resident"
      );
      await this.setState({
        roles: final,
      });
    });
  };

  GetProjects = () => {
    axios.get(`${URL}/project?page=1&limit=1000`).then(async (res) => {
      var newCategories = [{ id: "0", name: "-- Select Project --" }];
      for (var i = 0; i < res.data.data.length; i++) {
        newCategories.push({
          id: res.data.data[i].id + "",
          name: res.data.data[i].name + " || " + res.data.data[i].code,
        });
      }
      await this.setState({
        projects: newCategories,
      });
    });
  };

  GetCountry = () => {
    axios.get(`${BASE_URL}/api/countries?limit=1000`).then(async (res) => {
      var newCategories = [{ id: "0", name: "-- Select Country --" }];
      for (var i = 0; i < res.data.data.length; i++) {
        newCategories.push({
          id: res.data.data[i].id + "",
          name: res.data.data[i].name,
        });
      }
      await this.setState({
        allCountry: newCategories,
      });
    });
  };

  RoleChanges = (val) => {
    this.setState({ role_id: val.id });
  };

  CountryChange = async (val) => {
    this.setState({ country: val.id });
    if (val.id) {
      axios
        .get(`${BASE_URL}/api/states/${val.id}?limit=1000`)
        .then(async (res) => {
          var newCategories = [{ id: "0", name: "-- Select State --" }];
          for (var i = 0; i < res.data.data.length; i++) {
            newCategories.push({
              id: res.data.data[i].id + "",
              name: res.data.data[i].name,
            });
          }
          await this.setState({
            allState: newCategories,
          });
        });
    } else {
      await this.setState({
        allState: [{ id: "0", name: "-- Select Country First --" }],
      });
    }
  };

  StateChange = async (val) => {
    this.setState({ state1: val.id });
    if (val.id) {
      axios
        .get(`${BASE_URL}/api/cities/${val.id}?limit=1000`)
        .then(async (res) => {
          var newCategories = [{ id: "0", name: "-- Select State --" }];
          for (var i = 0; i < res.data.data.length; i++) {
            newCategories.push({
              id: res.data.data[i].id + "",
              name: res.data.data[i].name,
            });
          }
          await this.setState({
            allCity: newCategories,
          });
        });
    } else {
      await this.setState({
        allCity: [{ id: "0", name: "-- Select Country First --" }],
      });
    }
  };

  cityChange = (val) => {
    this.setState({ city: val.id });
  };

  ProjectChanges = (val) => {
    this.setState({ project_id: val.id });
  };

  AddData = () => {
    this.setState({
      name: "",
      mobile_no: "",
      email: "",
      staff_code: "",
      nfc_code: "",
      username: "",
      country: "",
      city: "",
      state1: "",
      post_code: "",
      address: "",
      addditional_information: null,
      password: "",
      password_confirmation: "",
      role_id: "0",
      project_id: "0",
      image: null,
      msg: "",
      error: "",
    });
    this.props.history.push("/client/staff");
  };

  componentWillMount() {
    this.GetRoles();
    this.GetProjects();
    this.GetCountry();
  }

  checkValidation = async () => {
    if (this.state.name === "") {
      toast.error("Please enter a name!");
      this.setState({
        msg: "",
        error: "Name is empty",
      });
      return false;
    } else if (this.state.email === "") {
      toast.error("Please enter an email!");
      this.setState({
        msg: "",
        error: "Email is empty",
      });
      return false;
    } else if (this.state.mobile_no === "") {
      toast.error("Please enter a mobile number!");
      this.setState({
        msg: "",
        error: "Mobile No is empty",
      });
      return false;
    } else if (this.state.role_id === "0") {
      toast.error("Please select a role!");
      this.setState({
        msg: "",
        error: "Role is empty",
      });
      return false;
    } else if (this.state.project_id === "0") {
      toast.error("Please select a project first!");
      this.setState({
        msg: "",
        error: "Project is empty",
      });
      return false;
    } else if (this.state.staff_Id === "") {
      toast.error("Please enter a staff code!");
      this.setState({
        msg: "",
        error: "Staff Code is empty!",
      });
      return false;
    } else if (this.state.staff_code === "") {
      toast.error("Please enter a QR Code");
      this.setState({
        msg: "",
        error: "QR Code is empty!",
      });
      return false;
    } else if (this.state.nfc_code === "") {
      toast.error("Please enter a NFC code!");
      this.setState({
        msg: "",
        error: "NFC Code is empty!",
      });
      return false;
    } else if (this.state.username === "") {
      toast.error("Please enter a user name!");
      this.setState({
        msg: "",
        error: "User Name is empty!",
      });
      return false;
    } else if (this.state.password === "") {
      toast.error("Please enter a password!");
      this.setState({
        msg: "",
        error: "Password is empty",
      });
      return false;
    } else if (this.state.password_confirmation === "") {
      toast.error("Please confirm your password!");
      this.setState({
        msg: "",
        error: "Please confirm your password!",
      });
      return false;
    } else if (this.state.password_confirmation !== this.state.password) {
      toast.error(
        "Passwords are not same ,Please confirm your password again!"
      );
      this.setState({
        msg: "",
        error: "Please confirm your password!",
      });
      return false;
    } else return true;
  };

  async Save() {
    var isValid = await false;
    await this.checkValidation().then((val) => {
      isValid = val;
    });
    await console.log(isValid);
    if (await isValid) {
      toast.success("Creating staff ...");
      var formData = new FormData();
      if (!isEmpty(this.state.name)) formData.append("name", this.state.name);
      if (!isEmpty(this.state.mobile_no))
        formData.append("mobile_no", this.state.mobile_no);
      if (!isEmpty(this.state.email))
        formData.append("email", this.state.email);
      if (!isEmpty(this.state.staff_code))
        formData.append("staff_code", this.state.staff_code);
      if (!isEmpty(this.state.nfc_code))
        formData.append("nfc_code", this.state.nfc_code);
      if (!isEmpty(this.state.username))
        formData.append("username", this.state.username);
      if (!isEmpty(this.state.avatar))
        formData.append("image", this.state.avatar);
      if (!isEmpty(this.state.country))
        formData.append("country", this.state.country);
      if (!isEmpty(this.state.city)) formData.append("city", this.state.city);
      if (!isEmpty(this.state.post_code))
        formData.append("post_code", this.state.post_code);
      if (!isEmpty(this.state.address))
        formData.append("address", this.state.address);
      if (!isEmpty(this.state.state1))
        formData.append("state", this.state.state1);
      if (!isEmpty(this.state.addditional_information))
        formData.append(
          "addditional_information",
          this.state.addditional_information
        );
      if (!isEmpty(this.state.staff_Id))
        formData.append("staff_id", this.state.staff_Id);
      if (!isEmpty(this.state.password))
        formData.append("password", this.state.password);
      if (!isEmpty(this.state.password_confirmation))
        formData.append(
          "password_confirmation",
          this.state.password_confirmation
        );
      if (!isEmpty(this.state.role_id))
        formData.append("role_id", this.state.role_id);
      formData.append("project_id", this.state.project_id);
      if (this.state.image) formData.append("file", this.state.image);
      var object = {};
      formData.forEach(function(value, key) {
        object[key] = value;
      });
      var json = JSON.stringify(object);
      console.log(json);
      axios
        .post(`${URL}/client_staff`, formData)
        .then(async (res) => {
          toast.success("Staff Saved Successfully!");
          this.setState(
            {
              msg: "Staff Saved Successfully!",
              error: "",
              name: "",
              mobile_no: "",
              email: "",
              staff_code: "",
              nfc_code: "",
              username: "",
              country: "", 
              city: "",
              state1: "",
              post_code: "",
              address: "",
              addditional_information: null,
              password: "",
              password_confirmation: "",
              role_id: "0",
              image: null,
              avatar: null,
            },
            () => {
              this.props.history.push("/client/staff");
            }
          );
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  UploadFile(e) {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        this.setState({ imagePreview2: reader.result });
      }
    };
    reader.readAsDataURL(e.target.files[0]);
    this.setState({ image: e.target.files[0] });
  }

  UploadFiles(e) {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        this.setState({ imagePreview1: reader.result });
      }
    };
    reader.readAsDataURL(e.target.files[0]);
    this.setState({ avatar: e.target.files[0] });
    this.setState({ image: e.target.files[0] });
  }

  render() {
    const customStyles = {
      control: (base) => ({
        ...base,
        height: 33,
        minHeight: 33,
        border: "1px solid #E4E6EF",
        borderRadius: "3px",
        fontSize: 10.5,
        fontWeight: 400,
        paddingTop: -25,
      }),
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <div className="row">
            <div className="col-sm-6 col-md-8 col-lg-8"></div>
            <div
              className="col-sm-6 col-md-4 col-lg-4"
              style={{ float: "right" }}
            ></div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <Card>
                <CardHeader
                  title="Add Staff"
                  icon={
                    <i
                      className="flaticon-employees mr-3 mb-3"
                      style={{ fontSize: "35px", color: "#3F4254" }}
                    ></i>
                  }
                >
                  <CardHeaderToolbar>
                    <Link
                      type="button"
                      className="btn btn-primary"
                      name="add_button"
                      to="/client/staff"
                    >
                      <span className="svg-icon menu-icon mr-2">
                        <svg
                          viewBox="0 0 24 24"
                          version="1.1"
                          class="kt-svg-icon"
                        >
                          <g
                            stroke="none"
                            stroke-width="1"
                            fill="none"
                            fill-rule="evenodd"
                          >
                            <rect
                              id="bound"
                              x="0"
                              y="0"
                              width="24"
                              height="24"
                            />
                            <path
                              d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z"
                              id="Combined-Shape"
                              fill="#000000"
                              opacity="0.3"
                            />
                            <path
                              d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z"
                              id="Combined-Shape"
                              fill="#000000"
                            />
                            <rect
                              id="Rectangle-152"
                              fill="#000000"
                              opacity="0.3"
                              x="10"
                              y="9"
                              width="7"
                              height="2"
                              rx="1"
                            />
                            <rect
                              id="Rectangle-152-Copy-2"
                              fill="#000000"
                              opacity="0.3"
                              x="7"
                              y="9"
                              width="2"
                              height="2"
                              rx="1"
                            />
                            <rect
                              id="Rectangle-152-Copy-3"
                              fill="#000000"
                              opacity="0.3"
                              x="7"
                              y="13"
                              width="2"
                              height="2"
                              rx="1"
                            />
                            <rect
                              id="Rectangle-152-Copy"
                              fill="#000000"
                              opacity="0.3"
                              x="10"
                              y="13"
                              width="7"
                              height="2"
                              rx="1"
                            />
                            <rect
                              id="Rectangle-152-Copy-5"
                              fill="#000000"
                              opacity="0.3"
                              x="7"
                              y="17"
                              width="2"
                              height="2"
                              rx="1"
                            />
                            <rect
                              id="Rectangle-152-Copy-4"
                              fill="#000000"
                              opacity="0.3"
                              x="10"
                              y="17"
                              width="7"
                              height="2"
                              rx="1"
                            />
                          </g>
                        </svg>
                      </span>
                      List
                    </Link>
                  </CardHeaderToolbar>
                </CardHeader>
                <CardBody className="row">
                  <h4 className="col-12 mt-2 mb-4">Staff Detail</h4>
                  <div className="col-md-8 col-6">
                    <div className="row">
                      <Form.Group className=" col-12">
                        <Form.Label>Name</Form.Label>
                        <div class="input-group ">
                          <input
                            onKeyPress={this.CheckKey}
                            className="form-control form-control-sm "
                            type="text"
                            placeholder="Name"
                            value={this.state.name}
                            name="name"
                            onChange={this.onChange}
                          ></input>
                          <div class="input-group-prepend">
                            <span class="input-group-text">
                              <i
                                class="flaticon-user"
                                style={{ fontSize: "12.5px" }}
                              ></i>
                            </span>
                          </div>
                        </div>
                      </Form.Group>

                      <Form.Group className="col-md-6 col-12">
                        <Form.Label>Email</Form.Label>
                        <div class="input-group ">
                          <input
                            onKeyPress={this.CheckKey}
                            className="form-control form-control-sm"
                            type="email"
                            placeholder="Email"
                            value={this.state.email}
                            name="email"
                            onChange={this.onChange}
                          ></input>
                          <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1">
                              <i
                                class="flaticon-email"
                                style={{ fontSize: "12.5px" }}
                              ></i>
                            </span>
                          </div>
                        </div>
                      </Form.Group>
                      <Form.Group className="col-md-6 col-12">
                        <Form.Label>Mobile No.</Form.Label>
                        <div class="input-group ">
                          <input
                            onKeyPress={this.CheckKey}
                            className="form-control form-control-sm"
                            type="text"
                            placeholder="Mobile No."
                            value={this.state.mobile_no}
                            name="mobile_no"
                            onChange={this.onChange}
                          ></input>
                          <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1">
                              <i
                                class="flaticon-smartphone"
                                style={{ fontSize: "12.5px" }}
                              ></i>
                            </span>
                          </div>
                        </div>
                      </Form.Group>
                      <Form.Group className="col-md-6 col-12">
                        <Form.Label>Country</Form.Label>
                        <Select
                          styles={customStyles}
                          name="country"
                          options={this.state.allCountry}
                          onChange={this.CountryChange}
                          value={this.state.allCountry.filter(
                            ({ id }) => id === this.state.country
                          )}
                          getOptionLabel={({ name }) => name}
                          getOptionValue={({ id }) => id}
                        />
                      </Form.Group>

                      <Form.Group className="col-md-6 col-12">
                        <Form.Label>State</Form.Label>
                        <Select
                          styles={customStyles}
                          name="state1"
                          options={this.state.allState}
                          onChange={this.StateChange}
                          value={this.state.allState.filter(
                            ({ id }) => id === this.state.state1
                          )}
                          getOptionLabel={({ name }) => name}
                          getOptionValue={({ id }) => id}
                        />
                      </Form.Group>

                      <Form.Group className="col-md-6 col-12">
                        <Form.Label>City</Form.Label>
                        <Select
                          styles={customStyles}
                          name="city"
                          options={this.state.allCity}
                          onChange={this.cityChange}
                          value={this.state.allCity.filter(
                            ({ id }) => id === this.state.city
                          )}
                          getOptionLabel={({ name }) => name}
                          getOptionValue={({ id }) => id}
                        />
                      </Form.Group>
                      <Form.Group className="col-md-6 col-12">
                        <Form.Label>Post Code</Form.Label>
                        <div class="input-group ">
                          <input
                            onKeyPress={this.CheckKey}
                            className="form-control form-control-sm"
                            type="text"
                            placeholder="Post Code"
                            value={this.state.post_code}
                            name="post_code"
                            onChange={this.onChange}
                          ></input>
                          <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1">
                              <i
                                class="flaticon-qr-code"
                                style={{ fontSize: "12.5px" }}
                              ></i>
                            </span>
                          </div>
                        </div>
                      </Form.Group>
                      <Form.Group className="col-12">
                        <Form.Label>Address</Form.Label>
                        <div class="input-group ">
                          <input
                            onKeyPress={this.CheckKey}
                            className="form-control form-control-sm"
                            type="text"
                            placeholder="Address"
                            value={this.state.address}
                            name="address"
                            onChange={this.onChange}
                          ></input>
                          <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1">
                              <i
                                class="flaticon-edit"
                                style={{ fontSize: "12.5px" }}
                              ></i>
                            </span>
                          </div>
                        </div>
                      </Form.Group>
                      <Form.Group className=" col-12">
                        <Form.Label>Additional Information</Form.Label>
                        <div class="input-group ">
                          <input
                            onKeyPress={this.CheckKey}
                            className="form-control form-control-sm"
                            type="text"
                            placeholder="Additional Information"
                            value={this.state.addditional_information}
                            name="addditional_information"
                            onChange={this.onChange}
                          ></input>
                          <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1">
                              <i
                                class="flaticon-description"
                                style={{ fontSize: "12.5px" }}
                              ></i>
                            </span>
                          </div>
                        </div>
                      </Form.Group>
                    </div>
                  </div>
                  <div className="col-md-4 col-6">
                    <div className="row">
                      <Form.Group
                        className="col-12 "
                        style={{ marginTop: "-10px" }}
                      >
                        {/* <Form.Label>Image</Form.Label> */}
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            position: "relative",
                            width: "100%",
                          }}
                        >
                          {this.state.imagePreview1 ? (
                            <p
                              style={{
                                position: "absolute",
                                top: "0%",
                                fontSize: "14px",
                                left: "65%",
                                color: "red",
                              }}
                            >
                              <button
                                onClick={() => {
                                  this.setState({
                                    imagePreview1: "",
                                    avatar: "",
                                  });
                                }}
                                class="btn btn-xs btn-default btn-text-primary btn-hover-danger btn-icon btn-icon-danger "
                                title="Delete"
                                ng-click="Delete(role.srNo)"
                              >
                                <span class="svg-icon svg-icon-md">
                                  <svg viewBox="0 0 24 24" version="1.1">
                                    <g
                                      stroke="none"
                                      stroke-width="1"
                                      fill="none"
                                      fill-rule="evenodd"
                                    >
                                      <rect
                                        x="0"
                                        y="0"
                                        width="24"
                                        height="24"
                                      ></rect>
                                      <path
                                        d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                        fill="#000000"
                                        fill-rule="nonzero"
                                      ></path>
                                      <path
                                        d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                        fill="#000000"
                                        opacity="0.3"
                                      ></path>
                                    </g>
                                  </svg>
                                </span>
                              </button>
                            </p>
                          ) : (
                            ""
                          )}

                          <Avatar
                            alt="res"
                            id="target"
                            style={{ width: "100px", height: "100px" }}
                            src={this.state.imagePreview1}
                            onClick={() => this.fileInput1.click()}
                          />
                        </div>
                        <p
                          onClick={() => this.fileInput1.click()}
                          className="text-center mt-5"
                          style={{ cursor: "pointer" }}
                        >
                          Select an image
                        </p>

                        <input
                          onKeyPress={this.CheckKey}
                          className="form-control form-control-sm d-none"
                          onChange={(e) => this.UploadFiles(e)}
                          type="file"
                          ref={(fileInput) => (this.fileInput1 = fileInput)}
                        ></input>
                      </Form.Group>
                      <Form.Group className="col-12">
                        <Form.Label>Project</Form.Label>
                        <Select
                          styles={customStyles}
                          name="project_id"
                          options={this.state.projects}
                          onChange={this.ProjectChanges}
                          value={this.state.projects.filter(
                            ({ id }) => id === this.state.project_id
                          )}
                          getOptionLabel={({ name }) => name}
                          getOptionValue={({ id }) => id}
                        />
                      </Form.Group>
                      <Form.Group className="col-12">
                        <Form.Label>Role</Form.Label>
                        <Select
                          styles={customStyles}
                          name="role_id"
                          options={this.state.roles}
                          onChange={this.RoleChanges}
                          value={this.state.roles.filter(
                            ({ id }) => id === this.state.role_id
                          )}
                          getOptionLabel={({ name }) => name}
                          getOptionValue={({ id }) => id}
                        />
                      </Form.Group>
                      <Form.Group className="col-12">
                        <Form.Label>Staff Code</Form.Label>
                        <div class="input-group ">
                          <input
                            onKeyPress={this.CheckKey}
                            className="form-control form-control-sm"
                            type="text"
                            placeholder="Staff Code"
                            value={this.state.staff_Id}
                            name="staff_Id"
                            onChange={(e) => {
                              this.onChange(e);
                            }}
                          ></input>
                          <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1">
                              <i
                                class="flaticon-qr-code"
                                style={{ fontSize: "12.5px" }}
                              ></i>
                            </span>
                          </div>
                        </div>
                      </Form.Group>
                      <Form.Group className="col-12">
                        <Form.Label>QR Code</Form.Label>
                        <div class="input-group ">
                          <input
                            onKeyPress={this.CheckKey}
                            className="form-control form-control-sm"
                            type="text"
                            placeholder="QR Code"
                            value={this.state.staff_code}
                            name="staff_code"
                            onChange={(e) => {
                              this.onChange(e);
                              this.setState({ nfc_code: e.target.value });
                            }}
                          ></input>
                          <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1">
                              <i
                                class="flaticon-qr-code"
                                style={{ fontSize: "12.5px" }}
                              ></i>
                            </span>
                          </div>
                        </div>
                      </Form.Group>
                      <Form.Group className="col-12">
                        <Form.Label>NFC Code</Form.Label>{" "}
                        <div class="input-group ">
                          <input
                            onKeyPress={this.CheckKey}
                            className="form-control form-control-sm"
                            type="text"
                            placeholder="NFC Code"
                            value={this.state.nfc_code}
                            name="nfc_code"
                            onChange={this.onChange}
                          ></input>
                          <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1">
                              <i
                                class="flaticon-qr-code"
                                style={{ fontSize: "12.5px" }}
                              ></i>
                            </span>
                          </div>
                        </div>
                      </Form.Group>
                    </div>
                  </div>

                  <h4 className="col-12 mt-2 mb-4">Login Information</h4>
                  <Form.Group className="col-md-6 col-12">
                    <Form.Label>User Name</Form.Label>
                    <div className="input-group">
                      <input
                        onKeyPress={this.CheckKey}
                        className="form-control form-control-sm"
                        type="text"
                        placeholder="User Name"
                        value={this.state.username}
                        name="username"
                        onChange={this.onChange}
                      ></input>
                      <div class="input-group-prepend">
                        <span class="input-group-text">
                          <i
                            class="flaticon-user"
                            style={{ fontSize: "12.5px" }}
                          ></i>
                        </span>
                      </div>
                    </div>
                  </Form.Group>
                  <Form.Group className="col-md-6 col-12">
                    <Form.Label>Password Expiry</Form.Label>
                    <input
                      onKeyPress={this.CheckKey}
                      className="form-control form-control-sm"
                      type="date"
                      placeholder="Password Expiry"
                      value={this.state.password_expiry}
                      name="password_expiry"
                      onChange={this.onChange}
                    ></input>
                  </Form.Group>
                  <Form.Group className="col-md-6 col-12">
                    <Form.Label>Password</Form.Label>
                    <div class="input-group ">
                      <input
                        type={this.state.show1 === true ? "text" : "password"}
                        onKeyPress={this.CheckKey}
                        className="form-control form-control-sm"
                        placeholder="Password"
                        value={this.state.password}
                        name="password"
                        onChange={this.onChange}
                      ></input>
                      <InputGroup.Append>
                        <InputGroup.Text>
                          <i
                            onClick={() =>
                              this.setState({ show1: !this.state.show1 })
                            }
                            className={
                              this.state.show1
                                ? "fas fa-eye-slash"
                                : "fas fa-eye"
                            }
                          ></i>
                        </InputGroup.Text>
                      </InputGroup.Append>
                    </div>
                  </Form.Group>
                  <Form.Group className="col-md-6 col-12">
                    <Form.Label>Confirm Password</Form.Label>
                    <div class="input-group ">
                      <input
                        onKeyPress={this.CheckKey}
                        className="form-control form-control-sm"
                        type={this.state.show2 === true ? "text" : "password"}
                        placeholder="Confirm Password"
                        value={this.state.password_confirmation}
                        name="password_confirmation"
                        onChange={this.onChange}
                      ></input>
                      <InputGroup.Append>
                        <InputGroup.Text>
                          <i
                            onClick={() =>
                              this.setState({ show2: !this.state.show2 })
                            }
                            className={
                              this.state.show2
                                ? "fas fa-eye-slash"
                                : "fas fa-eye"
                            }
                          ></i>
                        </InputGroup.Text>
                      </InputGroup.Append>
                    </div>
                  </Form.Group>

                  {/* <Form.Group className="col-md-6 col-12">
                    <Form.Label>Image</Form.Label>
                    <Avatar
                      alt="res"
                      id="target"
                      style={{ width: "80px", height: "80px" }}
                      src={this.state.imagePreview2}
                      onClick={() => this.fileInput2.click()}
                    />
                    <input
                      onKeyPress={this.CheckKey}
                      className="form-control form-control-sm d-none"
                      onChange={(e) => this.UploadFile(e)}
                      ref={(fileInput) => (this.fileInput2 = fileInput)}
                      type="file"
                    ></input>
                  </Form.Group> */}
                </CardBody>
                <div>
                  <button
                    className="btn btn-primary   mr-4 mb-2"
                    onClick={() => {
                      this.Save();
                    }}
                    style={{ float: "right" }}
                  >
                    <span className="svg-icon menu-icon mr-2">
                      <svg
                        viewBox="0 0 24 24"
                        version="1.1"
                        class="kt-svg-icon"
                      >
                        <g
                          stroke="none"
                          stroke-width="1"
                          fill="none"
                          fill-rule="evenodd"
                        >
                          <polygon id="Shape" points="0 0 24 0 24 24 0 24" />
                          <path
                            d="M17,4 L6,4 C4.79111111,4 4,4.7 4,6 L4,18 C4,19.3 4.79111111,20 6,20 L18,20 C19.2,20 20,19.3 20,18 L20,7.20710678 C20,7.07449854 19.9473216,6.94732158 19.8535534,6.85355339 L17,4 Z M17,11 L7,11 L7,4 L17,4 L17,11 Z"
                            id="Shape"
                            fill="#000000"
                            fill-rule="nonzero"
                          />
                          <rect
                            id="Rectangle-16"
                            fill="#000000"
                            opacity="0.3"
                            x="12"
                            y="4"
                            width="3"
                            height="5"
                            rx="0.5"
                          />
                        </g>
                      </svg>
                    </span>
                    Create
                  </button>

                  <button
                    className="btn btn-danger mr-3 mb-2"
                    onClick={() => this.AddData()}
                    name="cancel"
                    style={{ float: "right" }}
                  >
                    <span className="svg-icon menu-icon mr-2">
                      <svg
                        viewBox="0 0 24 24"
                        version="1.1"
                        class="kt-svg-icon"
                      >
                        <g
                          stroke="none"
                          stroke-width="1"
                          fill="none"
                          fill-rule="evenodd"
                        >
                          <g
                            id="Group"
                            transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)"
                            fill="#000000"
                          >
                            <rect
                              id="Rectangle-185"
                              x="0"
                              y="7"
                              width="16"
                              height="2"
                              rx="1"
                            />
                            <rect
                              id="Rectangle-185-Copy"
                              opacity="0.3"
                              transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) "
                              x="0"
                              y="7"
                              width="16"
                              height="2"
                              rx="1"
                            />
                          </g>
                        </g>
                      </svg>
                    </span>
                    Cancel
                  </button>
                </div>
              </Card>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(AddStaff);
