import React, { useEffect, useState } from "react";
import { Select, MenuItem } from "@material-ui/core";
import SVG from "react-inlinesvg";

import { MixedWidget1 } from "../widgets";
import { toAbsoluteUrl } from "../../_helpers";
import axios from "axios";
const URL = process.env.REACT_APP_API_URL;

export function Demo1Dashboard() {
  const [dashData, setDashData] = useState(() => {
    return [];
  });
  const [filter, setFilter] = useState(() => {
    return "month";
  });
  const onChange = async (e) => {
    console.log(e.target);
    await changeFilter(e.target.value);
    await axios
      .get(`${URL}/api/guard/dashboard?filter=${e.target.value}`)
      .then((res) => setDashData(res.data));
  };

  function changeFilter(filter) {
    setFilter(filter);
  }
  // useEffect(() => {
  //   axios
  //     .get(`${URL}/api/guard/dashboard?filter=${filter}`)
  //     .then((res) => setDashData(res.data));
  // }, []);
  return (
    <>
      <div className="row">
        <div className="row col-9 m-0 mt-2 mb-2">
          <h3>Welcome to Secure Resi !</h3>
        </div>

        {/* <div className="row col-3 m-0 mt-2 mb-2">
          <Select
            value={filter}
            onChange={(e) => onChange(e)}
            fullWidth
            displayEmpty
            margin="dense"
            id="currenct"
            placeholder="Filter"
            variant="outlined"
            style={{
              borderRadius: "0px",
              height: "31px",
              fontSize: "11px",
            }}
          >
            <MenuItem value="" disabled>
              Select Filter
            </MenuItem>
            <MenuItem value="today">Daily</MenuItem>
            <MenuItem value="weekly">Weekly</MenuItem>
            <MenuItem value="month">Monthly</MenuItem>

             <MenuItem value="store manager">Store Manager</MenuItem>
                <MenuItem value="cashier">Cashier</MenuItem>
                <MenuItem value="back office">Back Office</MenuItem>  
          </Select>
        </div>
        <div className="row col-12 m-0 mt-2">
          <div className=" col-6 row mb-2">
            <div className="col-5 bg-light-primary px-6 py-8  rounded-xl">
              <span className="svg-icon svg-icon-3x svg-icon-primary d-block my-2 d-flex justify-content-between align-items-center">
                <svg
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                  class="kt-svg-icon"
                >
                  <g
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <rect id="bound" x="0" y="0" width="24" height="24" />
                    <path
                      d="M4,4 L11.6314229,2.5691082 C11.8750185,2.52343403 12.1249815,2.52343403 12.3685771,2.5691082 L20,4 L20,13.2830094 C20,16.2173861 18.4883464,18.9447835 16,20.5 L12.5299989,22.6687507 C12.2057287,22.8714196 11.7942713,22.8714196 11.4700011,22.6687507 L8,20.5 C5.51165358,18.9447835 4,16.2173861 4,13.2830094 L4,4 Z"
                      id="Path-50"
                      fill="#000000"
                      opacity="0.3"
                    />
                    <path
                      d="M11.1750002,14.75 C10.9354169,14.75 10.6958335,14.6541667 10.5041669,14.4625 L8.58750019,12.5458333 C8.20416686,12.1625 8.20416686,11.5875 8.58750019,11.2041667 C8.97083352,10.8208333 9.59375019,10.8208333 9.92916686,11.2041667 L11.1750002,12.45 L14.3375002,9.2875 C14.7208335,8.90416667 15.2958335,8.90416667 15.6791669,9.2875 C16.0625002,9.67083333 16.0625002,10.2458333 15.6791669,10.6291667 L11.8458335,14.4625 C11.6541669,14.6541667 11.4145835,14.75 11.1750002,14.75 Z"
                      id="check-path"
                      fill="#000000"
                    />
                  </g>
                </svg>
                <div className="text-primary font-weight-bold font-size-h2">
                  {dashData?.totalCheckpoints}
                </div>
              </span>
              <div className="text-primary font-weight-bold font-size-h6 mt-2">
                Checkpoints
              </div>
            </div>

            <div className="col-1"></div>

            <div className="col-5 bg-light-warning px-6 py-8  rounded-xl ">
              <span className="svg-icon svg-icon-3x svg-icon-warning d-block my-2 d-flex justify-content-between align-items-center">
                <svg
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                  class="kt-svg-icon"
                >
                  <g
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <rect id="bound" x="0" y="0" width="24" height="24" />
                    <path
                      d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z"
                      id="Combined-Shape"
                      fill="#000000"
                      opacity="0.3"
                    />
                    <path
                      d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z"
                      id="Combined-Shape"
                      fill="#000000"
                    />
                    <rect
                      id="Rectangle-152"
                      fill="#000000"
                      opacity="0.3"
                      x="7"
                      y="10"
                      width="5"
                      height="2"
                      rx="1"
                    />
                    <rect
                      id="Rectangle-152-Copy"
                      fill="#000000"
                      opacity="0.3"
                      x="7"
                      y="14"
                      width="9"
                      height="2"
                      rx="1"
                    />
                  </g>
                </svg>
                <div className="text-warning font-weight-bold font-size-h3">
                  {dashData?.totalPatrol}
                </div>
              </span>
              <div className="text-warning font-weight-bold font-size-h6 mt-2">
                Patrol Activity
              </div>
            </div>
          </div>
          <div className="col-6  row mb-2">
            <div className="col-5 bg-light-primary px-6 py-8  rounded-xl">
              <span className="svg-icon svg-icon-3x svg-icon-primary d-block my-2 d-flex justify-content-between align-items-center">
                <svg
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                  class="kt-svg-icon"
                >
                  <g
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <rect id="bound" x="0" y="0" width="24" height="24" />
                    <rect
                      id="Rectangle"
                      fill="#000000"
                      opacity="0.3"
                      transform="translate(12.000000, 7.000000) rotate(-180.000000) translate(-12.000000, -7.000000) "
                      x="11"
                      y="1"
                      width="2"
                      height="12"
                      rx="1"
                    />
                    <path
                      d="M17,8 C16.4477153,8 16,7.55228475 16,7 C16,6.44771525 16.4477153,6 17,6 L18,6 C20.209139,6 22,7.790861 22,10 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 L2,9.99305689 C2,7.7839179 3.790861,5.99305689 6,5.99305689 L7.00000482,5.99305689 C7.55228957,5.99305689 8.00000482,6.44077214 8.00000482,6.99305689 C8.00000482,7.54534164 7.55228957,7.99305689 7.00000482,7.99305689 L6,7.99305689 C4.8954305,7.99305689 4,8.88848739 4,9.99305689 L4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,10 C20,8.8954305 19.1045695,8 18,8 L17,8 Z"
                      id="Path-103"
                      fill="#000000"
                      fill-rule="nonzero"
                      opacity="0.3"
                    />
                    <path
                      d="M14.2928932,10.2928932 C14.6834175,9.90236893 15.3165825,9.90236893 15.7071068,10.2928932 C16.0976311,10.6834175 16.0976311,11.3165825 15.7071068,11.7071068 L12.7071068,14.7071068 C12.3165825,15.0976311 11.6834175,15.0976311 11.2928932,14.7071068 L8.29289322,11.7071068 C7.90236893,11.3165825 7.90236893,10.6834175 8.29289322,10.2928932 C8.68341751,9.90236893 9.31658249,9.90236893 9.70710678,10.2928932 L12,12.5857864 L14.2928932,10.2928932 Z"
                      id="Path-104"
                      fill="#000000"
                      fill-rule="nonzero"
                    />
                  </g>
                </svg>
                <div className="text-primary font-weight-bold font-size-h2">
                  {dashData?.attendanceIn}
                </div>
              </span>
              <div className="text-primary font-weight-bold font-size-h6">
                Attendance <b>In</b>
              </div>
            </div>

            <div className="col-1"></div>

            <div className="col-5 bg-light-danger px-6 py-8  rounded-xl ">
              <span className="svg-icon svg-icon-3x svg-icon-danger d-block my-2 d-flex justify-content-between align-items-center">
                <svg
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                  class="kt-svg-icon"
                >
                  <g
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <rect id="bound" x="0" y="0" width="24" height="24" />
                    <path
                      d="M2,13 C2,12.5 2.5,12 3,12 C3.5,12 4,12.5 4,13 C4,13.3333333 4,15 4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 C2,15 2,13.3333333 2,13 Z"
                      id="Path-57"
                      fill="#000000"
                      fill-rule="nonzero"
                      opacity="0.3"
                    />
                    <rect
                      id="Rectangle"
                      fill="#000000"
                      opacity="0.3"
                      x="11"
                      y="2"
                      width="2"
                      height="14"
                      rx="1"
                    />
                    <path
                      d="M12.0362375,3.37797611 L7.70710678,7.70710678 C7.31658249,8.09763107 6.68341751,8.09763107 6.29289322,7.70710678 C5.90236893,7.31658249 5.90236893,6.68341751 6.29289322,6.29289322 L11.2928932,1.29289322 C11.6689749,0.916811528 12.2736364,0.900910387 12.6689647,1.25670585 L17.6689647,5.75670585 C18.0794748,6.12616487 18.1127532,6.75845471 17.7432941,7.16896473 C17.3738351,7.57947475 16.7415453,7.61275317 16.3310353,7.24329415 L12.0362375,3.37797611 Z"
                      id="Path-102"
                      fill="#000000"
                      fill-rule="nonzero"
                    />
                  </g>
                </svg>
                <div className="text-danger font-weight-bold font-size-h3">
                  {dashData?.attendanceOut}
                </div>
              </span>
              <div className="text-danger font-weight-bold font-size-h6 mt-2">
                Attendance <b>Out</b>
              </div>
            </div>
          </div>
          <div className="col-6  row mb-2">
            <div className="col-5 bg-light-warning px-6 py-8  rounded-xl">
              <span className="svg-icon svg-icon-3x svg-icon-warning d-block my-2 d-flex justify-content-between align-items-center">
                <span class="svg-icon svg-icon-warning svg-icon-2x">
                  <span class="svg-icon svg-icon-warning svg-icon-2x">
                    <svg
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                      class="kt-svg-icon"
                    >
                      <g
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <rect id="bound" x="0" y="0" width="24" height="24" />
                        <path
                          d="M12,22 C7.02943725,22 3,17.9705627 3,13 C3,8.02943725 7.02943725,4 12,4 C16.9705627,4 21,8.02943725 21,13 C21,17.9705627 16.9705627,22 12,22 Z"
                          id="Mask"
                          fill="#000000"
                          opacity="0.3"
                        />
                        <path
                          d="M11.9630156,7.5 L12.0475062,7.5 C12.3043819,7.5 12.5194647,7.69464724 12.5450248,7.95024814 L13,12.5 L16.2480695,14.3560397 C16.403857,14.4450611 16.5,14.6107328 16.5,14.7901613 L16.5,15 C16.5,15.2109164 16.3290185,15.3818979 16.1181021,15.3818979 C16.0841582,15.3818979 16.0503659,15.3773725 16.0176181,15.3684413 L11.3986612,14.1087258 C11.1672824,14.0456225 11.0132986,13.8271186 11.0316926,13.5879956 L11.4644883,7.96165175 C11.4845267,7.70115317 11.7017474,7.5 11.9630156,7.5 Z"
                          id="Path-107"
                          fill="#000000"
                        />
                      </g>
                    </svg>
                  </span>
                </span>
                <div className="text-warning font-weight-bold font-size-h2">
                  {dashData?.totalScheduleRoutine}
                </div>
              </span>

              <div className="text-warning font-weight-bold font-size-h6 mt-2">
                Schedule Routine
              </div>
            </div>
            <div className="col-1"></div>
            <div className="col-5 bg-light-success px-6 py-8  rounded-xl">
              <span className="svg-icon svg-icon-3x svg-icon-success d-block my-2 d-flex justify-content-between align-items-center">
                <span class="svg-icon svg-icon-primary svg-icon-2x">
                  <span class="svg-icon svg-icon-primary svg-icon-2x">
                    <svg
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                      class="kt-svg-icon"
                    >
                      <g
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <rect id="bound" x="0" y="0" width="24" height="24" />
                        <path
                          d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z"
                          id="Combined-Shape"
                          fill="#000000"
                          opacity="0.3"
                        />
                        <path
                          d="M10.875,15.75 C10.6354167,15.75 10.3958333,15.6541667 10.2041667,15.4625 L8.2875,13.5458333 C7.90416667,13.1625 7.90416667,12.5875 8.2875,12.2041667 C8.67083333,11.8208333 9.29375,11.8208333 9.62916667,12.2041667 L10.875,13.45 L14.0375,10.2875 C14.4208333,9.90416667 14.9958333,9.90416667 15.3791667,10.2875 C15.7625,10.6708333 15.7625,11.2458333 15.3791667,11.6291667 L11.5458333,15.4625 C11.3541667,15.6541667 11.1145833,15.75 10.875,15.75 Z"
                          id="check-path"
                          fill="#000000"
                        />
                        <path
                          d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z"
                          id="Combined-Shape"
                          fill="#000000"
                        />
                      </g>
                    </svg>
                  </span>
                </span>
                <div className="text-success font-weight-bold font-size-h2">
                  {dashData?.scheduleEntriesOnTime}
                </div>
              </span>

              <div className="text-success font-weight-bold font-size-h6 mt-2">
                Schedule Activity <br />
                <small>
                  <b>(On Time)</b>
                </small>
              </div>
            </div>
          </div>
          <div className="col-6  row mb-2">
            <div className="col-5 bg-light-danger px-6 py-8  rounded-xl">
              <span className="svg-icon svg-icon-3x svg-icon-danger d-block my-2 d-flex justify-content-between align-items-center">
                <span class="svg-icon svg-icon-danger svg-icon-2x">
                  <svg
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                    class="kt-svg-icon"
                  >
                    <g
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <rect id="bound" x="0" y="0" width="24" height="24" />
                      <path
                        d="M4.5,3 L19.5,3 C20.3284271,3 21,3.67157288 21,4.5 L21,19.5 C21,20.3284271 20.3284271,21 19.5,21 L4.5,21 C3.67157288,21 3,20.3284271 3,19.5 L3,4.5 C3,3.67157288 3.67157288,3 4.5,3 Z M8,5 C7.44771525,5 7,5.44771525 7,6 C7,6.55228475 7.44771525,7 8,7 L16,7 C16.5522847,7 17,6.55228475 17,6 C17,5.44771525 16.5522847,5 16,5 L8,5 Z M10.5857864,14 L9.17157288,15.4142136 C8.78104858,15.8047379 8.78104858,16.4379028 9.17157288,16.8284271 C9.56209717,17.2189514 10.1952621,17.2189514 10.5857864,16.8284271 L12,15.4142136 L13.4142136,16.8284271 C13.8047379,17.2189514 14.4379028,17.2189514 14.8284271,16.8284271 C15.2189514,16.4379028 15.2189514,15.8047379 14.8284271,15.4142136 L13.4142136,14 L14.8284271,12.5857864 C15.2189514,12.1952621 15.2189514,11.5620972 14.8284271,11.1715729 C14.4379028,10.7810486 13.8047379,10.7810486 13.4142136,11.1715729 L12,12.5857864 L10.5857864,11.1715729 C10.1952621,10.7810486 9.56209717,10.7810486 9.17157288,11.1715729 C8.78104858,11.5620972 8.78104858,12.1952621 9.17157288,12.5857864 L10.5857864,14 Z"
                        id="Combined-Shape"
                        fill="#000000"
                      />
                    </g>
                  </svg>
                </span>
                <div className="text-danger font-weight-bold font-size-h2">
                  {dashData?.schedulesEntryAfterTime}
                </div>
              </span>
              <div className="text-danger font-weight-bold font-size-h6 mt-2">
                Schedules Activity <br />
                <small>
                  <b>(After Time)</b>
                </small>
              </div>
            </div>

            <div className="col-1"></div>
            <div className="col-5 bg-light-success px-6 py-8  rounded-xl">
              <span className="svg-icon svg-icon-3x svg-icon-success d-block my-2 d-flex justify-content-between align-items-center">
                <svg
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                  class="kt-svg-icon"
                >
                  <g
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <rect id="bound" x="0" y="0" width="24" height="24" />
                    <path
                      d="M13.6855025,18.7082217 C15.9113859,17.8189707 18.682885,17.2495635 22,17 C22,16.9325178 22,13.1012863 22,5.50630526 L21.9999762,5.50630526 C21.9999762,5.23017604 21.7761292,5.00632908 21.5,5.00632908 C21.4957817,5.00632908 21.4915635,5.00638247 21.4873465,5.00648922 C18.658231,5.07811173 15.8291155,5.74261533 13,7 C13,7.04449645 13,10.79246 13,18.2438906 L12.9999854,18.2438906 C12.9999854,18.520041 13.2238496,18.7439052 13.5,18.7439052 C13.5635398,18.7439052 13.6264972,18.7317946 13.6855025,18.7082217 Z"
                      id="Combined-Shape"
                      fill="#000000"
                    />
                    <path
                      d="M10.3144829,18.7082217 C8.08859955,17.8189707 5.31710038,17.2495635 1.99998542,17 C1.99998542,16.9325178 1.99998542,13.1012863 1.99998542,5.50630526 L2.00000925,5.50630526 C2.00000925,5.23017604 2.22385621,5.00632908 2.49998542,5.00632908 C2.50420375,5.00632908 2.5084219,5.00638247 2.51263888,5.00648922 C5.34175439,5.07811173 8.17086991,5.74261533 10.9999854,7 C10.9999854,7.04449645 10.9999854,10.79246 10.9999854,18.2438906 L11,18.2438906 C11,18.520041 10.7761358,18.7439052 10.4999854,18.7439052 C10.4364457,18.7439052 10.3734882,18.7317946 10.3144829,18.7082217 Z"
                      id="Path-41-Copy"
                      fill="#000000"
                      opacity="0.3"
                    />
                  </g>
                </svg>
                <div className="text-success font-weight-bold font-size-h2">
                  {dashData?.totalLogEntries}
                </div>
              </span>
              <div className="text-success font-weight-bold font-size-h6 mt-2">
                Log Book
              </div>
            </div>
          </div>

          <div className=" col-6 row mb-2">
            <div className="col-5 bg-light-primary px-6 py-8  rounded-xl">
              <span className="svg-icon svg-icon-3x svg-icon-primary d-block my-2 d-flex justify-content-between align-items-center">
                <span class="svg-icon svg-icon-primary svg-icon-2x">
                  <svg
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                    class="kt-svg-icon"
                  >
                    <g
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <rect id="bound" x="0" y="0" width="24" height="24" />
                      <path
                        d="M7.14319965,19.3575259 C7.67122143,19.7615175 8.25104409,20.1012165 8.87097532,20.3649307 L7.89205065,22.0604779 C7.61590828,22.5387706 7.00431787,22.7026457 6.52602525,22.4265033 C6.04773263,22.150361 5.88385747,21.5387706 6.15999985,21.0604779 L7.14319965,19.3575259 Z M15.1367085,20.3616573 C15.756345,20.0972995 16.3358198,19.7569961 16.8634386,19.3524415 L17.8320512,21.0301278 C18.1081936,21.5084204 17.9443184,22.1200108 17.4660258,22.3961532 C16.9877332,22.6722956 16.3761428,22.5084204 16.1000004,22.0301278 L15.1367085,20.3616573 Z"
                        id="Combined-Shape"
                        fill="#000000"
                      />
                      <path
                        d="M12,21 C7.581722,21 4,17.418278 4,13 C4,8.581722 7.581722,5 12,5 C16.418278,5 20,8.581722 20,13 C20,17.418278 16.418278,21 12,21 Z M19.068812,3.25407593 L20.8181344,5.00339833 C21.4039208,5.58918477 21.4039208,6.53893224 20.8181344,7.12471868 C20.2323479,7.71050512 19.2826005,7.71050512 18.696814,7.12471868 L16.9474916,5.37539627 C16.3617052,4.78960984 16.3617052,3.83986237 16.9474916,3.25407593 C17.5332781,2.66828949 18.4830255,2.66828949 19.068812,3.25407593 Z M5.29862906,2.88207799 C5.8844155,2.29629155 6.83416297,2.29629155 7.41994941,2.88207799 C8.00573585,3.46786443 8.00573585,4.4176119 7.41994941,5.00339833 L5.29862906,7.12471868 C4.71284263,7.71050512 3.76309516,7.71050512 3.17730872,7.12471868 C2.59152228,6.53893224 2.59152228,5.58918477 3.17730872,5.00339833 L5.29862906,2.88207799 Z"
                        id="Combined-Shape"
                        fill="#000000"
                        opacity="0.3"
                      />
                      <path
                        d="M11.9630156,7.5 L12.0475062,7.5 C12.3043819,7.5 12.5194647,7.69464724 12.5450248,7.95024814 L13,12.5 L16.2480695,14.3560397 C16.403857,14.4450611 16.5,14.6107328 16.5,14.7901613 L16.5,15 C16.5,15.2109164 16.3290185,15.3818979 16.1181021,15.3818979 C16.0841582,15.3818979 16.0503659,15.3773725 16.0176181,15.3684413 L11.3986612,14.1087258 C11.1672824,14.0456225 11.0132986,13.8271186 11.0316926,13.5879956 L11.4644883,7.96165175 C11.4845267,7.70115317 11.7017474,7.5 11.9630156,7.5 Z"
                        id="Path-107"
                        fill="#000000"
                      />
                    </g>
                  </svg>
                </span>
                <div className="text-primary font-weight-bold font-size-h2">
                  {dashData?.totalPatrolSchdule}
                </div>
              </span>
              <div className="text-primary font-weight-bold font-size-h6 mt-2">
                Patrol Schdule
              </div>
            </div>

            <div className="col-1"></div>
            <div className="col-5 bg-light-success px-6 py-8  rounded-xl">
              <span className="svg-icon svg-icon-3x svg-icon-success d-block my-2 d-flex justify-content-between align-items-center">
                <svg
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                  class="kt-svg-icon"
                >
                  <g
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <rect id="bound" x="0" y="0" width="24" height="24" />
                    <path
                      d="M12,21 C7.581722,21 4,17.418278 4,13 C4,8.581722 7.581722,5 12,5 C16.418278,5 20,8.581722 20,13 C20,17.418278 16.418278,21 12,21 Z"
                      id="Combined-Shape"
                      fill="#000000"
                      opacity="0.3"
                    />
                    <path
                      d="M13,5.06189375 C12.6724058,5.02104333 12.3386603,5 12,5 C11.6613397,5 11.3275942,5.02104333 11,5.06189375 L11,4 L10,4 C9.44771525,4 9,3.55228475 9,3 C9,2.44771525 9.44771525,2 10,2 L14,2 C14.5522847,2 15,2.44771525 15,3 C15,3.55228475 14.5522847,4 14,4 L13,4 L13,5.06189375 Z"
                      id="Combined-Shape"
                      fill="#000000"
                    />
                    <path
                      d="M16.7099142,6.53272645 L17.5355339,5.70710678 C17.9260582,5.31658249 18.5592232,5.31658249 18.9497475,5.70710678 C19.3402718,6.09763107 19.3402718,6.73079605 18.9497475,7.12132034 L18.1671361,7.90393167 C17.7407802,7.38854954 17.251061,6.92750259 16.7099142,6.53272645 Z"
                      id="Combined-Shape"
                      fill="#000000"
                    />
                    <path
                      d="M11.9630156,7.5 L12.0369844,7.5 C12.2982526,7.5 12.5154733,7.70115317 12.5355117,7.96165175 L12.9585886,13.4616518 C12.9797677,13.7369807 12.7737386,13.9773481 12.4984096,13.9985272 C12.4856504,13.9995087 12.4728582,14 12.4600614,14 L11.5399386,14 C11.2637963,14 11.0399386,13.7761424 11.0399386,13.5 C11.0399386,13.4872031 11.0404299,13.4744109 11.0414114,13.4616518 L11.4644883,7.96165175 C11.4845267,7.70115317 11.7017474,7.5 11.9630156,7.5 Z"
                      id="Path-107"
                      fill="#000000"
                    />
                  </g>
                </svg>
                <div className="text-success font-weight-bold font-size-h2">
                  {dashData?.patrolScheduleEntryOnTime}
                </div>
              </span>
              <div className="text-success font-weight-bold font-size-h6 mt-2">
                Patrol Schedule Activity <br />{" "}
                <small>
                  <b>(On Time)</b>
                </small>
              </div>
            </div>
          </div>
          <div className="col-6  row mb-2">
            <div className="col-5 bg-light-danger px-6 py-8  rounded-xl">
              <span className="svg-icon svg-icon-3x svg-icon-danger d-block my-2 d-flex justify-content-between align-items-center">
                <svg
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                  class="kt-svg-icon"
                >
                  <g
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <rect id="bound" x="0" y="0" width="24" height="24" />
                    <path
                      d="M3.5,21 L20.5,21 C21.3284271,21 22,20.3284271 22,19.5 L22,8.5 C22,7.67157288 21.3284271,7 20.5,7 L10,7 L7.43933983,4.43933983 C7.15803526,4.15803526 6.77650439,4 6.37867966,4 L3.5,4 C2.67157288,4 2,4.67157288 2,5.5 L2,19.5 C2,20.3284271 2.67157288,21 3.5,21 Z"
                      id="Combined-Shape"
                      fill="#000000"
                      opacity="0.3"
                    />
                    <path
                      d="M10.5857864,14 L9.17157288,12.5857864 C8.78104858,12.1952621 8.78104858,11.5620972 9.17157288,11.1715729 C9.56209717,10.7810486 10.1952621,10.7810486 10.5857864,11.1715729 L12,12.5857864 L13.4142136,11.1715729 C13.8047379,10.7810486 14.4379028,10.7810486 14.8284271,11.1715729 C15.2189514,11.5620972 15.2189514,12.1952621 14.8284271,12.5857864 L13.4142136,14 L14.8284271,15.4142136 C15.2189514,15.8047379 15.2189514,16.4379028 14.8284271,16.8284271 C14.4379028,17.2189514 13.8047379,17.2189514 13.4142136,16.8284271 L12,15.4142136 L10.5857864,16.8284271 C10.1952621,17.2189514 9.56209717,17.2189514 9.17157288,16.8284271 C8.78104858,16.4379028 8.78104858,15.8047379 9.17157288,15.4142136 L10.5857864,14 Z"
                      id="Combined-Shape"
                      fill="#000000"
                    />
                  </g>
                </svg>
                <div className="text-danger font-weight-bold font-size-h2">
                  {dashData?.patrolScheduleEntryAfterTime}
                </div>
              </span>
              <div className="text-danger font-weight-bold font-size-h6 mt-2">
                Patrol Schedule Activity <br />{" "}
                <small>
                  <b>(After Time)</b>
                </small>
              </div>
            </div>{" "}
            <div className="col-1"></div>
            <div className="col-5 bg-light-warning px-6 py-8  rounded-xl">
              <span className="svg-icon svg-icon-3x svg-icon-warning d-block my-2 d-flex justify-content-between align-items-center">
                <svg
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                  class="kt-svg-icon"
                >
                  <g
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <rect id="bound" x="0" y="0" width="24" height="24" />
                    <path
                      d="M3.5,21 L20.5,21 C21.3284271,21 22,20.3284271 22,19.5 L22,8.5 C22,7.67157288 21.3284271,7 20.5,7 L10,7 L7.43933983,4.43933983 C7.15803526,4.15803526 6.77650439,4 6.37867966,4 L3.5,4 C2.67157288,4 2,4.67157288 2,5.5 L2,19.5 C2,20.3284271 2.67157288,21 3.5,21 Z"
                      id="Combined-Shape"
                      fill="#000000"
                    />
                  </g>
                </svg>
                <div className="text-warning font-weight-bold font-size-h2">
                  {dashData?.totalOperationEntries}
                </div>
              </span>
              <div className="text-warning font-weight-bold font-size-h6">
                Operation Activity
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
}
