import React, { useState } from "react";
import { Divider, Button, TextField, Grid } from "@material-ui/core";
function SearchForTable({ onSubmitValue }) {
  const [value, setValue] = useState("");
  return (
    <>
      <div style={{ height: "50px", padding: "15px 15px 36px 15px" }}>
        <Grid container>
          <Grid item xs={8}></Grid>

          <Grid item xs={3}>
            <TextField
              className="inputRounded"
              style={{ borderRadius: "0px", width: "100%" }}
              color="secondary"
              onChange={(e) => setValue(e.target.value)}
              variant="outlined"
              size="small"
            />
          </Grid>
          <Grid item xs={1}>
            <Button
              onClick={() => onSubmitValue(value)}
              color="secondary"
              variant="contained"
              style={{
                color: "white",
                width: "100%",
                height: "100%",
                borderRadius: "0px",
                boxShadow: "none",
              }}
            >
              Search
            </Button>
          </Grid>
        </Grid>
      </div>
      <Divider className="mb-5" />
    </>
  );
}
export default SearchForTable;
