import React, { Component } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import ReactToPrint from "react-to-print";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Table, Alert, Dropdown } from "react-bootstrap";
import { GetNestedObjectString, fetchByDotOperator } from "./ObjectFunctions";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SearchForTable from "../SearchForTable";
import { Avatar, Menu, Button, MenuItem, Checkbox } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ExportCsv from "../../ECommerce/components/Export";
import { Link } from "react-router-dom";
import $ from "jquery";
import _ from "lodash";
import moment from "moment";
import isEmpty from "../../../Validations/is-empty";
import MakeCSVData from "./MakeCSVData";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
const URL = process.env.REACT_APP_API_URL;
class DataGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pageNo: "1",
      itemsPerPage: "20",
      maxSize: 0,
      image: "",
      qr: "",
      totalPages: "0",
      totalItems: 0,
      sort: false,
      permissionArray: [],
      anchorEl: null,
      anchorEls: null,
      csvData: [],
      search: "",
      list: props.list,
      show: props.list,
      select: ["none"],
      URL: `${URL}/${this.props.end_point}`,
    };
    this.PaginateData = this.PaginateData.bind(this);
    this.GetPaginatedData = this.GetPaginatedData.bind(this);
    this.GetPageData = this.GetPageData.bind(this);
    this.PreviousPageData = this.PreviousPageData.bind(this);
    this.NextPageData = this.NextPageData.bind(this);
    this.GetTotalPages = this.GetTotalPages.bind(this);
    this.SetButtons = this.SetButtons.bind(this);
    this.Delete = this.Delete.bind(this);
  }

  PaginateData(PageNo) {
    this.setState({ pageNo: PageNo + "" }, () => {
      axios
        .get(
          `${this.state.URL}?page=${this.state.pageNo}&limit=${this.state.itemsPerPage}&search=${this.state.search}`
        )
        .then((res) => {
          this.setState(
            {
              data: res.data.data,
              totalPages: Math.ceil(
                res.data.meta.total / res.data.meta.per_page
              ),
              totalItems: res.data.meta.total,
            },
            () => {
              this.SetButtons();
            }
          );
        });
    });
  }

  async GetTotalPages() {
    await axios
      .get(
        `${this.state.URL}?page=1&limit=${this.state.itemsPerPage}&search=${this.state.search}`
      )
      .then((res) => {
        this.setState(
          {
            data: res.data.data,
            totalPages: Math.ceil(res.data.meta.total / res.data.meta.per_page),
            totalItems: res.data.meta.total,
          },
          () => {
            this.SetButtons();
          }
        );
      })
      .catch((err) => {
      });
    let array = [];
    if (JSON.parse(localStorage.getItem("permission"))) {
      JSON.parse(localStorage.getItem("permission")).map(async (v) => {
        await array.push(v.name);
      });
    }
    this.setState(
      {
        permissionArray: array,
      }
    );
  }

  GetPaginatedData = () => {
    axios
      .get(
        `${this.state.URL}?page=${this.state.pageNo}&limit=${this.state.itemsPerPage}&search=${this.state.search}`
      )
      .then((res) => {
        this.setState(
          {
            data: res.data.data,
            totalPages: Math.ceil(res.data.meta.total / res.data.meta.per_page),
            totalItems: res.data.meta.total,
          },
          () => {
            this.SetButtons();
          }
        );
      });
  };

  handleDeleteALert = (id) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>Are you sure?</h1>
            <p>You want to delete this {this.props.title}?</p>
            <button
              className="btn btn-danger"
              onClick={onClose}
              style={{ marginRight: 10 }}
            >
              No
            </button>
            <button
              className="btn btn-primary"
              onClick={() => {
                this.Delete(id);
                onClose();
              }}
            >
              Yes, Delete it!
            </button>
          </div>
        );
      },
    });
  };

  Delete = (id) => {
    toast.success(`Deleting ${this.props.title_small} ...`);
    axios
      .delete(`${this.state.URL}/${id}`)
      .then(async (res) => {
        toast.success(`${this.props.title} deleted successfully!`);
        this.setState(
          {
            msg: res.data.message,
            error: "",
          },
          () => {
            this.GetTotalPages();
          }
        );
      })
      .catch((err) => {
      });
  };

  GetPageData = (id) => {
    var data = {
      itemsPerPage: this.state.itemsPerPage,
      search: this.state.search,
      pageNo: this.state.pageNo,
    };
    if (id === "1") {
      this.setState({ pageNo: $("#bnt1").text() }, () => {
        data.pageNo = this.state.pageNo;
      });
    } else if (id === "2") {
      this.setState({ pageNo: $("#bnt2").text() }, () => {
        data.pageNo = this.state.pageNo;
      });
    } else if (id === "3") {
      this.setState({ pageNo: $("#bnt3").text() }, () => {
        data.pageNo = this.state.pageNo;
      });
    } else if (id === "4") {
      this.setState({ pageNo: $("#bnt4").text() }, () => {
        data.pageNo = this.state.pageNo;
      });
    } else if (id === "5") {
      this.setState({ pageNo: $("#bnt5").text() }, () => {
        data.pageNo = this.state.pageNo;
      });
    }
    axios
      .get(`${this.state.URL}?page=${id}&limit=${this.state.itemsPerPage}`)
      .then(async (res) => {
        this.setState(
          {
            data: res.data.data,
            totalPages: Math.ceil(res.data.meta.total / res.data.meta.per_page),
            totalItems: res.data.meta.total,
          },
          () => {
            this.SetButtons();
          }
        );
      });
  };

  PreviousPageData = () => {
    if (this.state.pageNo !== 1) {
      this.setState({ pageNo: Number(this.state.pageNo) - 1 + "" }, () => {
        axios
          .get(
            `${this.state.URL}?page=${this.state.pageNo}&limit=${this.state.itemsPerPage}&search=${this.state.search}`
          )
          .then(async (res) => {
            this.setState(
              {
                data: res.data.data,
                totalPages: Math.ceil(
                  res.data.meta.total / res.data.meta.per_page
                ),
                totalItems: res.data.meta.total,
              },
              () => {
                this.SetButtons();
              }
            );
          });
      });
    }
  };

  NextPageData = () => {
    this.setState({ pageNo: Number(this.state.pageNo) + 1 + "" }, () => {
      axios
        .get(
          `${this.state.URL}?page=${this.state.pageNo}&limit=${this.state.itemsPerPage}&search=${this.state.search}`
        )
        .then(async (res) => {
          this.setState({
            data: res.data.data,
            totalPages: Math.ceil(res.data.meta.total / res.data.meta.per_page),
            totalItems: res.data.meta.total,
          });
        });
    });
  };

  SetButtons = () => {
    if (parseInt(this.state.pageNo) === 1) {
      $("#btnFirst").prop("disabled", true);
      $("#btnPrevious").prop("disabled", true);
      $("#btnNext").prop("disabled", false);
      $("#btnLast").prop("disabled", false);
      $("#bnt1").removeClass("btn-outline-primary");
      $("#bnt1").addClass("btn-primary");
      $("#bnt2").removeClass("btn-primary");
      $("#bnt2").addClass("btn-outline-primary");
      $("#bnt4").removeClass("btn-primary");
      $("#bnt4").addClass("btn-outline-primary");
      $("#bnt3").removeClass("btn-primary");
      $("#bnt3").addClass("btn-outline-primary");
      $("#bnt5").removeClass("btn-primary");
      $("#bnt5").addClass("btn-outline-primary");
      $("#bnt1").html("1");
      $("#bnt2").html("2");
      $("#bnt3").html("3");
      $("#bnt4").html("4");
      $("#bnt5").html("5");
      if (parseInt(this.state.totalPages) === 1) {
        $("#btnNext").prop("disabled", true);
        $("#btnLast").prop("disabled", true);
      }
    } else if (
      parseInt(this.state.pageNo) === parseInt(this.state.totalPages)
    ) {
      $("#btnFirst").prop("disabled", false);
      $("#btnPrevious").prop("disabled", false);
      $("#btnNext").prop("disabled", true);
      $("#btnLast").prop("disabled", true);
      if (parseInt(this.state.totalPages) < 6) {
        $("#bnt" + this.state.totalPages).removeClass("btn-outline-primary");
        $("#bnt" + this.state.totalPages).addClass("btn-primary");
        for (var i = parseInt(this.state.totalPages) - 1; i > 0; i--) {
          $("#bnt" + i).removeClass("btn-primary");
          $("#bnt" + i).addClass("btn-outline-primary");
        }
      } else {
        $("#bnt5").removeClass("btn-outline-primary");
        $("#bnt5").addClass("btn-primary");
        $("#bnt4").removeClass("btn-primary");
        $("#bnt4").addClass("btn-outline-primary");
        $("#bnt3").removeClass("btn-primary");
        $("#bnt3").addClass("btn-outline-primary");
        $("#bnt2").removeClass("btn-primary");
        $("#bnt2").addClass("btn-outline-primary");
        $("#bnt1").removeClass("btn-primary");
        $("#bnt1").addClass("btn-outline-primary");

        $("#bnt1").html("" + (parseInt(this.state.pageNo) - 4));
        $("#bnt2").html("" + (parseInt(this.state.pageNo) - 3));
        $("#bnt3").html("" + (parseInt(this.state.pageNo) - 2));
        $("#bnt4").html("" + (parseInt(this.state.pageNo) - 1));
        $("#bnt5").html("" + parseInt(this.state.pageNo));
      }
    } else {
      $("#btnFirst").prop("disabled", false);
      $("#btnPrevious").prop("disabled", false);
      $("#btnNext").prop("disabled", false);
      $("#btnLast").prop("disabled", false);
      if (parseInt(this.state.totalPages) < 6) {
        for (var j = parseInt(this.state.totalPages); j > 0; j--) {
          $("#bnt" + j).removeClass("btn-primary");
          $("#bnt" + j).addClass("btn-outline-primary");
        }
        $("#bnt" + this.state.pageNo).removeClass("btn-outline-primary");
        $("#bnt" + this.state.pageNo).addClass("btn-primary");
      } else {
        if (parseInt(this.state.pageNo) === 2) {
          $("#bnt5").addClass("btn-outline-primary");
          $("#bnt5").removeClass("btn-primary");
          $("#bnt4").removeClass("btn-primary");
          $("#bnt4").addClass("btn-outline-primary");
          $("#bnt3").removeClass("btn-primary");
          $("#bnt3").addClass("btn-outline-primary");
          $("#bnt2").removeClass("btn-outline-primary");
          $("#bnt2").addClass("btn-primary");
          $("#bnt1").removeClass("btn-primary");
          $("#bnt1").addClass("btn-outline-primary");
        } else if (parseInt(this.state.pageNo) === 3) {
          $("#bnt1").html("1");
          $("#bnt2").html("2");
          $("#bnt3").html("3");
          $("#bnt4").html("4");
          $("#bnt5").html("5");
          $("#bnt5").addClass("btn-outline-primary");
          $("#bnt5").removeClass("btn-primary");
          $("#bnt4").removeClass("btn-primary");
          $("#bnt4").addClass("btn-outline-primary");
          $("#bnt2").removeClass("btn-primary");
          $("#bnt2").addClass("btn-outline-primary");
          $("#bnt3").removeClass("btn-outline-primary");
          $("#bnt3").addClass("btn-primary");
          $("#bnt1").removeClass("btn-primary");
          $("#bnt1").addClass("btn-outline-primary");
        } else {
          if (
            parseInt(this.state.totalPages) - parseInt(this.state.pageNo) >
            2
          ) {
            $("#bnt1").html("" + (parseInt(this.state.pageNo) - 2));
            $("#bnt2").html("" + (parseInt(this.state.pageNo) - 1));
            $("#bnt3").html("" + parseInt(this.state.pageNo));
            $("#bnt4").html("" + (parseInt(this.state.pageNo) + 1));
            $("#bnt5").html("" + (parseInt(this.state.pageNo) + 2));
          } else if (
            parseInt(this.state.totalPages) - parseInt(this.state.pageNo) >
            1
          ) {
            $("#bnt1").html("" + (parseInt(this.state.pageNo) - 2));
            $("#bnt2").html("" + (parseInt(this.state.pageNo) - 1));
            $("#bnt3").html("" + parseInt(this.state.pageNo));
            $("#bnt4").html("" + (parseInt(this.state.pageNo) + 1));
            $("#bnt5").html("" + (parseInt(this.state.pageNo) + 2));
            $("#bnt5").addClass("btn-outline-primary");
            $("#bnt5").removeClass("btn-primary");
            $("#bnt4").removeClass("btn-primary");
            $("#bnt4").addClass("btn-outline-primary");
            $("#bnt2").removeClass("btn-outline-primary");
            $("#bnt2").addClass("btn-outline-primary");
            $("#bnt3").removeClass("btn-outline-primary");
            $("#bnt3").addClass("btn-primary");
            $("#bnt1").removeClass("btn-primary");
            $("#bnt1").addClass("btn-outline-primary");
          } else if (
            parseInt(this.state.totalPages) - parseInt(this.state.pageNo) ===
            1
          ) {
            $("#bnt1").html("" + (parseInt(this.state.pageNo) - 3));
            $("#bnt2").html("" + (parseInt(this.state.pageNo) - 2));
            $("#bnt3").html("" + (parseInt(this.state.pageNo) - 1));
            $("#bnt4").html("" + parseInt(this.state.pageNo));
            $("#bnt5").html("" + (parseInt(this.state.pageNo) + 1));
            $("#bnt5").addClass("btn-outline-primary");
            $("#bnt5").removeClass("btn-primary");
            $("#bnt3").removeClass("btn-primary");
            $("#bnt3").addClass("btn-outline-primary");
            $("#bnt2").removeClass("btn-outline-primary");
            $("#bnt2").addClass("btn-outline-primary");
            $("#bnt4").removeClass("btn-outline-primary");
            $("#bnt4").addClass("btn-primary");
            $("#bnt1").removeClass("btn-primary");
            $("#bnt1").addClass("btn-outline-primary");
          }
        }
      }
    }
  };

  componentWillMount() {
    this.setState({ show: this.props.list });
    this.GetTotalPages();
  }

  handleClick = (path, b) => {
    const char = this.state.data;
    const ch = _.orderBy(char, [path], [this.state.sort ? "asc" : "desc"]);
    this.setState({ data: [] });
    this.setState({ data: ch });
    this.setState({ sort: !this.state.sort });
    this.setState({ select: [b] });
  };

  componentDidMount() {
    this.GetTotalPages();
  }

  showClick = (v) => {
    let newArray = [];
    if (this.state.show.includes(v)) {
      newArray = this.state.show.filter((e) => e !== v);
      this.setState({ show: newArray });
    } else {
      newArray = this.state.show;
      newArray.push(v);
      this.setState({ show: newArray });
    }
  };

  onSubmitValue = (val) => {
    this.setState({ search: val ? val : "", pageNo: 1 }, () => {
      this.GetTotalPages();
    });
  };

  render() {
    const handleClicks = (event) => {
      this.setState({ anchorEl: event.currentTarget });
    };

    const handleClose = () => {
      this.setState({ anchorEl: null });
    };
    return (
      <Card ref={(el) => (this.componentRef = el)}>
        <CardHeader title={this.props.title} className="notPrint">
          <CardHeaderToolbar className="notPrint">
            <Button
              color="secondary"
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClicks}
              className="mt-5"
            >
              <VisibilityIcon />
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={this.state.anchorEl}
              keepMounted
              open={Boolean(this.state.anchorEl)}
              onClose={handleClose}
            >
              {this.state.list.map((v, k) => (
                <MenuItem
                  key={k}
                  style={{ textTransform: "capitalize" }}
                  onClick={() => this.showClick(v)}
                >
                  <Checkbox checked={this.state.show.includes(v)} /> {v}
                </MenuItem>
              ))}
            </Menu>
            <ExportCsv
              csvData={MakeCSVData(this.state.data, this.props.columns)}
              fileName={this.props.file_name}
            />
            <ReactToPrint
              trigger={() => {
                return (
                  <Button
                    className="ml-5 mr-5"
                    color="secondary"
                    variant="outlined"
                    onClick={this.print}
                  >
                    Print
                  </Button>
                );
              }}
              content={() => this.componentRef}
            />
            {this.props.new_action === "on_same_page" ? (
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => this.props.AddData()}
                name="add_button"
              >
                Add New {this.props.title}
              </button>
            ) : (
              <Link
                type="button"
                className="btn btn-primary"
                name="add_button"
                to={this.props.new_record_url}
              >
                Add New {this.props.title}
              </Link>
            )}
          </CardHeaderToolbar>
        </CardHeader>

        <div className="notPrint">
          <SearchForTable onSubmitValue={this.onSubmitValue} />{" "}
        </div>
        <div className="row mt-5 ml-5 mr-5 print d-none ">
          <div className="col-12">
            <h4
              className="text-center mt-5 mb-5"
              style={{ marginBottom: "25px" }}
            >
              {this.props.title}
            </h4>
          </div>
        </div>
        <CardBody>
          <Table responsive>
            <thead>
              <tr>
                {this.props.columns.map((column) =>
                  this.state.show.includes(column.column_name) ? (
                    <th
                      width={column.width}
                      onClick={() => {
                        this.handleClick(column.field_name, column.column_name);
                      }}
                    >
                      <span style={{ display: "flex" }}>
                        {column.column_name}
                        <div
                          className="d-flex"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <ExpandLessIcon
                            style={{
                              height: "16px",
                              color:
                                this.state.sort === false &&
                                this.state.select.includes(column.column_name)
                                  ? "black"
                                  : "#898f8b",
                              marginTop: "-3px",
                            }}
                          />
                          <ExpandMoreIcon
                            style={{
                              height: "16px",
                              color:
                                this.state.sort === true &&
                                this.state.select.includes(column.column_name)
                                  ? "black"
                                  : "#898f8b",
                              marginTop: "-8px",
                            }}
                          />
                        </div>
                      </span>
                    </th>
                  ) : null
                )}
                <th width="20%" className="text-center notPrint">
                  Events
                </th>
              </tr>
            </thead>
            <tbody>
              {this.state.data ? (
                this.state.data.map((obj, k) => (
                  <tr key={k}>
                    {this.props.columns.map((column) =>
                      this.state.show.includes(column.column_name) ? (
                        <td>
                          {column.column_name === "Sr" ? (
                            k + 1
                          ) : column.type === "object" ? (
                            fetchByDotOperator(
                              obj,
                              GetNestedObjectString(column.object_name) +
                                column.field_name
                            )
                          ) : column.type === "date_time" ? (
                            moment
                              .utc(obj[column.field_name])
                              .local()
                              .format("DD-MM-YYYY hh:mm A") !==
                            "Invalid date" ? (
                              moment
                                .utc(obj[column.field_name])
                                .local()
                                .format("DD-MM-YYYY hh:mm A")
                            ) : (
                              ""
                            )
                          ) : column.type === "hour" ? (
                            moment
                              .utc(obj[column.field_name])
                              .local()
                              .format("hh:mm A") !== "Invalid Time" ? (
                              moment
                                .utc(obj[column.field_name])
                                .local()
                                .format("hh:mm A")
                            ) : (
                              ""
                            )
                          ) : column.type === "image" ? (
                            <Avatar
                              src={obj[column.field_name]}
                              width="25px"
                              height="25px"
                              onClick={() =>
                                this.openModel(obj[column.field_name])
                              }
                            ></Avatar>
                          ) : column.type === "audio" ? (
                            obj[column.field_name] ? (
                              <img
                                src={require("../../../../assets/play.png")}
                                onClick={() =>
                                  this.openModel(
                                    obj[column.field_name],
                                    "audio"
                                  )
                                }
                                width="25px"
                                height="25px"
                                alt=""
                              />
                            ) : (
                              ""
                            )
                          ) : column.type === "static_dropdown" ? (
                            <Dropdown>
                              <Dropdown.Toggle
                                id="dropdown-basic"
                                size="sm"
                                variant="secondary"
                              >
                                {obj.status}{" "}
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                {column.staticOptionsObject.length > 0
                                  ? column.staticOptions.map((option) => (
                                      <Dropdown.Item
                                        onClick={() =>
                                          this.props.updateDropdownObject(
                                            obj,
                                            fetchByDotOperator(
                                              obj,
                                              GetNestedObjectString(
                                                column.staticOptionsObject
                                              ) + option
                                            )
                                          )
                                        }
                                      >
                                        {fetchByDotOperator(
                                          obj,
                                          GetNestedObjectString(
                                            column.staticOptionsObject
                                          ) + option
                                        )}
                                      </Dropdown.Item>
                                    ))
                                  : null}
                              </Dropdown.Menu>
                            </Dropdown>
                          ) : column.type === "time_difference" ? (
                            obj[column.field_name1] ? (
                              moment(obj[column.field_name]).diff(
                                obj[column.field_name1],
                                "hours"
                              )
                            ) : (
                              "On Duty"
                            )
                          ) : (
                            obj[column.field_name]
                          )}
                        </td>
                      ) : null
                    )}
                    <td class="text-center notPrint">
                      {this.state.permissionArray.includes(
                        "Update " + this.props.permission_label
                      ) ? (
                        this.props.new_action === "on_same_page" ? (
                          <button
                            onClick={() => this.props.UpdateData(obj)}
                            class="btn btn-xs btn-default btn-text-primary btn-hover-primary btn-icon btn-icon-primary mr-2"
                            title="Edit details"
                            ng-click="UpdateData(role)"
                          >
                            <span class="svg-icon svg-icon-md">
                              <svg viewBox="0 0 24 24" version="1.1">
                                <g
                                  stroke="none"
                                  stroke-width="1"
                                  fill="none"
                                  fill-rule="evenodd"
                                >
                                  <rect
                                    x="0"
                                    y="0"
                                    width="24"
                                    height="24"
                                  ></rect>
                                  <path
                                    d="M12.2674799,18.2323597 L12.0084872,5.45852451 C12.0004303,5.06114792 12.1504154,4.6768183 12.4255037,4.38993949 L15.0030167,1.70195304 L17.5910752,4.40093695 C17.8599071,4.6812911 18.0095067,5.05499603 18.0083938,5.44341307 L17.9718262,18.2062508 C17.9694575,19.0329966 17.2985816,19.701953 16.4718324,19.701953 L13.7671717,19.701953 C12.9505952,19.701953 12.2840328,19.0487684 12.2674799,18.2323597 Z"
                                    fill="#000000"
                                    fill-rule="nonzero"
                                    transform="translate(14.701953, 10.701953) rotate(-135.000000) translate(-14.701953, -10.701953) "
                                  ></path>
                                  <path
                                    d="M12.9,2 C13.4522847,2 13.9,2.44771525 13.9,3 C13.9,3.55228475 13.4522847,4 12.9,4 L6,4 C4.8954305,4 4,4.8954305 4,6 L4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 L2,6 C2,3.790861 3.790861,2 6,2 L12.9,2 Z"
                                    fill="#000000"
                                    fill-rule="nonzero"
                                    opacity="0.3"
                                  ></path>
                                </g>
                              </svg>
                            </span>
                          </button>
                        ) : (
                          <Link
                            class="btn btn-xs btn-default btn-text-primary btn-hover-primary btn-icon btn-icon-primary mr-2"
                            title="Edit details"
                            to={{
                              pathname: this.props.edit_record_url,
                              state: {obj},
                              param2: "update",
                            }}
                          >
                            <span class="svg-icon svg-icon-md">
                              <svg viewBox="0 0 24 24" version="1.1">
                                <g
                                  stroke="none"
                                  stroke-width="1"
                                  fill="none"
                                  fill-rule="evenodd"
                                >
                                  <rect
                                    x="0"
                                    y="0"
                                    width="24"
                                    height="24"
                                  ></rect>
                                  <path
                                    d="M12.2674799,18.2323597 L12.0084872,5.45852451 C12.0004303,5.06114792 12.1504154,4.6768183 12.4255037,4.38993949 L15.0030167,1.70195304 L17.5910752,4.40093695 C17.8599071,4.6812911 18.0095067,5.05499603 18.0083938,5.44341307 L17.9718262,18.2062508 C17.9694575,19.0329966 17.2985816,19.701953 16.4718324,19.701953 L13.7671717,19.701953 C12.9505952,19.701953 12.2840328,19.0487684 12.2674799,18.2323597 Z"
                                    fill="#000000"
                                    fill-rule="nonzero"
                                    transform="translate(14.701953, 10.701953) rotate(-135.000000) translate(-14.701953, -10.701953) "
                                  ></path>
                                  <path
                                    d="M12.9,2 C13.4522847,2 13.9,2.44771525 13.9,3 C13.9,3.55228475 13.4522847,4 12.9,4 L6,4 C4.8954305,4 4,4.8954305 4,6 L4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 L2,6 C2,3.790861 3.790861,2 6,2 L12.9,2 Z"
                                    fill="#000000"
                                    fill-rule="nonzero"
                                    opacity="0.3"
                                  ></path>
                                </g>
                              </svg>
                            </span>
                          </Link>
                        )
                      ) : (
                        ""
                      )}
                      {this.state.permissionArray.includes(
                        "Delete " + this.props.permission_label
                      ) ? (
                        <button
                          onClick={() => this.handleDeleteALert(obj.id)}
                          class="btn btn-xs btn-default btn-text-primary btn-hover-danger btn-icon btn-icon-danger "
                          title="Delete"
                          ng-click="Delete(role.srNo)"
                        >
                          <span class="svg-icon svg-icon-md">
                            <svg viewBox="0 0 24 24" version="1.1">
                              <g
                                stroke="none"
                                stroke-width="1"
                                fill="none"
                                fill-rule="evenodd"
                              >
                                <rect x="0" y="0" width="24" height="24"></rect>
                                <path
                                  d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                  fill="#000000"
                                  fill-rule="nonzero"
                                ></path>
                                <path
                                  d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                  fill="#000000"
                                  opacity="0.3"
                                ></path>
                              </g>
                            </svg>
                          </span>
                        </button>
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <h3>Loading...</h3>
              )}
            </tbody>
            <tfoot className="notPrint">
              <tr>
                <td align="center" colSpan="7">
                  <div className="pull-right">
                    <button
                      id="btnFirst"
                      onClick={() => this.PaginateData(1)}
                      className="btn btn-rounded btn-sm btn-outline-primary mr-1"
                    >
                      First
                    </button>
                    <button
                      id="btnPrevious"
                      onClick={() => this.PreviousPageData()}
                      className="btn btn-rounded btn-sm btn-outline-primary mr-1"
                    >
                      Previous
                    </button>
                    {this.state.totalPages !== "0" ? (
                      <button
                        id="bnt1"
                        onClick={() => this.GetPageData("1")}
                        className="btn btn-rounded btn-sm btn-outline-primary mr-1"
                      >
                        1
                      </button>
                    ) : null}
                    {this.state.totalPages > "1" ? (
                      <button
                        id="bnt2"
                        onClick={() => this.GetPageData("2")}
                        className="btn btn-rounded btn-sm btn-outline-primary mr-1"
                      >
                        2
                      </button>
                    ) : null}
                    {this.state.totalPages > "2" ? (
                      <button
                        id="bnt3"
                        onClick={() => this.GetPageData("3")}
                        className="btn btn-rounded btn-sm btn-outline-primary mr-1"
                      >
                        3
                      </button>
                    ) : null}
                    {this.state.totalPages > "3" ? (
                      <button
                        id="bnt4"
                        onClick={() => this.GetPageData("4")}
                        className="btn btn-rounded btn-sm btn-outline-primary mr-1"
                      >
                        4
                      </button>
                    ) : null}
                    {this.state.totalPages > "4" ? (
                      <button
                        id="bnt5"
                        onClick={() => this.GetPageData("5")}
                        className="btn btn-rounded btn-sm btn-outline-primary mr-1"
                      >
                        5
                      </button>
                    ) : null}
                    <button
                      id="btnNext"
                      onClick={() => this.NextPageData()}
                      className="btn btn-rounded btn-sm btn-outline-primary mr-1"
                    >
                      Next
                    </button>
                    <button
                      id="btnLast"
                      onClick={() => this.PaginateData(this.state.totalPages)}
                      className="btn btn-rounded btn-sm btn-outline-primary"
                    >
                      Last
                    </button>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <label className="btn btn-primary  mt-2 pulll-left">
                        {this.state.pageNo * this.state.itemsPerPage -
                          this.state.itemsPerPage}
                        -
                        {this.state.pageNo === this.state.totalPages
                          ? this.state.totalItems
                          : this.state.pageNo * this.state.itemsPerPage}
                        / {this.state.totalItems} items
                      </label>
                    </div>
                    <div className="col-6">
                      <div className="row">
                        <div className="col-md-4"></div>
                        <div className="col-md-2">
                          <select
                            className="form-control form-control-sm mt-3 pull-right"
                            value={this.state.itemsPerPage}
                            name="itemsPerPage"
                            onChange={this.onChange}
                          >
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="500">500</option>
                          </select>
                        </div>
                        <div className="col-md-6">
                          <button className="btn btn-primary mt-2 pull-right col-8">
                            Page: {this.state.pageNo} /{this.state.totalPages}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tfoot>
          </Table>
        </CardBody>
      </Card>
    );
  }
}

export default DataGrid;
