/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";

export function AsideMenuList({ layoutProps }) {
  const [permissionArray, setPermissionArray] = useState([]);
  const history = useHistory();
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
          "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };
  useEffect(() => {
    let array = [];
    if (JSON.parse(localStorage.getItem("permission"))) {
      JSON.parse(localStorage.getItem("permission")).map(async (v) => {
        await array.push(v);
      });
    }
    setPermissionArray(array);
  }, []);

  return (
    <>
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        <li
          className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li>
        {permissionArray.some((s) => s.group === "staff") ||
        permissionArray.some((s) => s.group === "roles") ||
        permissionArray.some((s) => s.group === "project") ? (
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/staff",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="staff">
              <span className="svg-icon menu-icon">
                <svg
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                >
                  <g
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <rect x="0" y="0" width="24" height="24" />
                    <path
                      d="M5,8.6862915 L5,5 L8.6862915,5 L11.5857864,2.10050506 L14.4852814,5 L19,5 L19,9.51471863 L21.4852814,12 L19,14.4852814 L19,19 L14.4852814,19 L11.5857864,21.8994949 L8.6862915,19 L5,19 L5,15.3137085 L1.6862915,12 L5,8.6862915 Z M12,15 C13.6568542,15 15,13.6568542 15,12 C15,10.3431458 13.6568542,9 12,9 C10.3431458,9 9,10.3431458 9,12 C9,13.6568542 10.3431458,15 12,15 Z"
                      fill="#000000"
                    />
                  </g>
                </svg>
              </span>
              <span className="menu-text">Setup</span>
              <i className="menu-arrow" />
            </NavLink>
            <div className="menu-submenu">
              <i className="menu-arrow" />
              <ul className="menu-subnav">
                <li className="menu-item menu-item-parent" aria-haspopup="true">
                  <span className="menu-link">
                    <span className="menu-text">staff</span>
                  </span>
                </li>
                {permissionArray.some((s) => s.group === "project") ? (
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/client/project"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/client/project">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Project</span>
                    </NavLink>
                  </li>
                ) : (
                  ""
                )}
                {permissionArray.some((s) => s.group === "staff") ? (
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/client/staff"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/client/staff">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Staff</span>
                    </NavLink>
                  </li>
                ) : (
                  ""
                )}
                {permissionArray.some((s) => s.group === "role") ? (
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/client/roles"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/client/roles">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Roles</span>
                    </NavLink>
                  </li>
                ) : (
                  ""
                )}
                {permissionArray.some((s) => s.group === "role") ? (
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/client/permission"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/client/permission">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Permission</span>
                    </NavLink>
                  </li>
                ) : (
                  ""
                )}
              </ul>
            </div>
          </li>
        ) : (
          ""
        )}
        <li
          onClick={() => {
            localStorage.removeItem("access_token");
            localStorage.removeItem("permission");
            history.push("/logout");
          }}
          className={`menu-item menu-item-submenu`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/auth/login">
            <span className="svg-icon menu-icon  ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                version="1.1"
              >
                <g
                  stroke="none"
                  strokeWidth="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <rect x="0" y="0" width="24" height="24" />
                  <path
                    d="M14.0069431,7.00607258 C13.4546584,7.00607258 13.0069431,6.55855153 13.0069431,6.00650634 C13.0069431,5.45446114 13.4546584,5.00694009 14.0069431,5.00694009 L15.0069431,5.00694009 C17.2160821,5.00694009 19.0069431,6.7970243 19.0069431,9.00520507 L19.0069431,15.001735 C19.0069431,17.2099158 17.2160821,19 15.0069431,19 L3.00694311,19 C0.797804106,19 -0.993056895,17.2099158 -0.993056895,15.001735 L-0.993056895,8.99826498 C-0.993056895,6.7900842 0.797804106,5 3.00694311,5 L4.00694793,5 C4.55923268,5 5.00694793,5.44752105 5.00694793,5.99956624 C5.00694793,6.55161144 4.55923268,6.99913249 4.00694793,6.99913249 L3.00694311,6.99913249 C1.90237361,6.99913249 1.00694311,7.89417459 1.00694311,8.99826498 L1.00694311,15.001735 C1.00694311,16.1058254 1.90237361,17.0008675 3.00694311,17.0008675 L15.0069431,17.0008675 C16.1115126,17.0008675 17.0069431,16.1058254 17.0069431,15.001735 L17.0069431,9.00520507 C17.0069431,7.90111468 16.1115126,7.00607258 15.0069431,7.00607258 L14.0069431,7.00607258 Z"
                    fill="#000000"
                    fillRule="nonzero"
                    opacity="0.3"
                    transform="translate(9.006943, 12.000000) scale(-1, 1) rotate(-90.000000) translate(-9.006943, -12.000000) "
                  />
                  <rect
                    fill="#000000"
                    opacity="0.3"
                    transform="translate(14.000000, 12.000000) rotate(-270.000000) translate(-14.000000, -12.000000) "
                    x="13"
                    y="6"
                    width="2"
                    height="12"
                    rx="1"
                  />
                  <path
                    d="M21.7928932,9.79289322 C22.1834175,9.40236893 22.8165825,9.40236893 23.2071068,9.79289322 C23.5976311,10.1834175 23.5976311,10.8165825 23.2071068,11.2071068 L20.2071068,14.2071068 C19.8165825,14.5976311 19.1834175,14.5976311 18.7928932,14.2071068 L15.7928932,11.2071068 C15.4023689,10.8165825 15.4023689,10.1834175 15.7928932,9.79289322 C16.1834175,9.40236893 16.8165825,9.40236893 17.2071068,9.79289322 L19.5,12.0857864 L21.7928932,9.79289322 Z"
                    fill="#000000"
                    fillRule="nonzero"
                    transform="translate(19.500000, 12.000000) rotate(-90.000000) translate(-19.500000, -12.000000) "
                  />
                </g>
              </svg>
            </span>
            <span className="menu-text">Sign Out</span>
          </NavLink>
        </li>
      </ul>
    </>
  );
}
