import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { BrowserRouter, HashRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { AuthInit } from "./modules/Auth";
import { Routes } from "../app/Routes";
import { I18nProvider } from "../_metronic/i18n";
import { LayoutSplashScreen, MaterialThemeProvider } from "../_metronic/layout";
import setAuthToken from "./Utils/setAuthToken";
import jwt_decode from "jwt-decode";
import "react-toastify/dist/ReactToastify.min.css";
import { ToastContainer } from "react-toastify";
import axios from "axios";
const URL = process.env.REACT_APP_API_URL;

export default function App({ store, persistor, basename }) {
  useEffect(() => {
    axios.get(`${URL}/user`).then((res) => {
      localStorage.setItem(
        "permission",
        JSON.stringify(res.data.role.permissions)
      );
    });
  }, []);

  if (localStorage.access_token) {
    setAuthToken(localStorage.access_token);
    /*const decodedData = jwt_decode(localStorage.access_token);
    const currentTime = new Date() / 1000;
    console.lof(decodedData);
    if (decodedData.exp < currentTime) {
      setAuthToken(false);
      localStorage.removeItem('access_token');
      localStorage.removeItem('user');
      window.href.location('/auth/login');
    }*/
  }

  return (
    /* Provide Redux store */
    <Provider store={store}>
      <ToastContainer theme="colored" />

      {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
      <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
        {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
        <React.Suspense fallback={<LayoutSplashScreen />}>
          {/* Override `basename` (e.g: `homepage` in `package.json`) */}
          <HashRouter basename={basename}>
            {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
            <MaterialThemeProvider>
              {/* Provide `react-intl` context synchronized with Redux state.  */}
              <I18nProvider>
                {/* Render routes with provided `Layout`. */}
                <AuthInit>
                  <Routes />
                </AuthInit>
              </I18nProvider>
            </MaterialThemeProvider>
          </HashRouter>
        </React.Suspense>
      </PersistGate>
    </Provider>
  );
}
