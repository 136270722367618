import axios from "axios";
import {rolesSlice, callTypes} from "./rolesSlice";

const {actions} = rolesSlice;
let access_token = localStorage.getItem("access_token");
let headers = {headers: {Authorization: "Bearer " + access_token}}


export function getRoles() {
  console.log("11111......access tokennn  ..", headers)
  return axios.get('http://103.215.139.76/api/client/role?page=1&limit=10', headers  );
}

export const fetchRoles = () => dispatch => {
    console.log("fetch back")
  getRoles()
  .then(response => {
    // console.log("fetch back11", response)

    // const { total, data } =  response;
    const totalCount = response.data.meta.total;
    const entities = response.data.data;

    // add response to store
    dispatch(actions.rolesFetched({ totalCount, entities }));
  })
  .catch(error => {
    error.clientMessage = "Can't find customers";
    // dispatch(actions.catchError({ error, callType: callTypes.list }));
  });
};
export const createRoles = role => dispatch => {
    axios.post('http://103.215.139.76/api/client/role',role, headers  )
    .then(response => {

        dispatch(fetchRoles({}));

    //   console.log("222222222222222222role posted succes fully", response);
    //   const { total, data } =  response;
    //   const totalCount = response.data.meta.total;
    //   const entities = response.data.data;
  
  
    //   fetchRoles({ totalCount, entities });
    })
    .catch(error => {
    //   error.clientMessage = "Can't find customers";
    //   dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
  };

  export const deleteRoles = id => dispatch => {
    axios.delete('http://103.215.139.76/api/client/role/'+id , headers  )
    .then(response => {

        dispatch(fetchRoles({}));

    //   console.log("222222222222222222role posted succes fully", response);
    //   const { total, data } =  response;
    //   const totalCount = response.data.meta.total;
    //   const entities = response.data.data;
  
  
    //   fetchRoles({ totalCount, entities });
    })
    .catch(error => {
    //   error.clientMessage = "Can't find customers";
    //   dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
  };