import React, { Component } from "react";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import axios from "axios";
import isEmpty from "../../../../Validations/is-empty";
import { Form, InputGroup, Tab, Tabs } from "react-bootstrap";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { Link } from "react-router-dom";
import { Avatar } from "@material-ui/core";
import Select from "react-select";
import { TextInput } from "../../../Component/TextInput";
var formData = new FormData();
const URL = process.env.REACT_APP_API_URL;
const BASE_URL = process.env.REACT_APP_API_BASE_URL;
class AddProject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      code: "",
      no_of_checkpoints: "",
      no_of_gaurds: "",
      no_of_members: "",
      no_of_project_staff: "",
      country: "0",
      city: "0",
      state1: "0",
      post_code: "",
      address: "",
      contact_person_name: "",
      contact_person_email: "",
      contact_person_designation: "",
      contact_person_phone: "",
      contact_person_fax: "",
      latitude: "",
      longitude: "",
      geofence_radius: "",
      geocode: "",
      status: "ACTIVE",
      image: null,
      allCountry: [],
      allState: [],
      allCity: [],
      msg: "",
      error: "",
      imagePreview1: null,
      imagePreview2: null,
      show1: false,
      show2: false,
    };
    this.Save = this.Save.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  CheckKey = (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      this.Save();
    }
  };

  GetCountry = () => {
    axios.get(`${BASE_URL}/api/countries?limit=1000`).then(async (res) => {
      var newCategories = [{ id: "0", name: "-- Select Country --" }];
      for (var i = 0; i < res.data.data.length; i++) {
        newCategories.push({
          id: res.data.data[i].id + "",
          name: res.data.data[i].name,
        });
      }
      await this.setState({
        allCountry: newCategories,
      });
    });
  };

  CountryChange = async (val) => {
    this.setState({ country: val.id });
    if (val.id) {
      axios
        .get(`${BASE_URL}/api/states/${val.id}?limit=1000`)
        .then(async (res) => {
          var newCategories = [{ id: "0", name: "-- Select State --" }];
          for (var i = 0; i < res.data.data.length; i++) {
            newCategories.push({
              id: res.data.data[i].id + "",
              name: res.data.data[i].name,
            });
          }
          await this.setState({
            allState: newCategories,
          });
        });
    } else {
      await this.setState({
        allState: [{ id: "0", name: "-- Select Country First --" }],
      });
    }
  };

  StateChange = async (val) => {
    this.setState({ state1: val.id });
    if (val.id) {
      axios
        .get(`${BASE_URL}/api/cities/${val.id}?limit=1000`)
        .then(async (res) => {
          var newCategories = [{ id: "0", name: "-- Select State --" }];
          for (var i = 0; i < res.data.data.length; i++) {
            newCategories.push({
              id: res.data.data[i].id + "",
              name: res.data.data[i].name,
            });
          }
          await this.setState({
            allCity: newCategories,
          });
        });
    } else {
      await this.setState({
        allCity: [{ id: "0", name: "-- Select Country First --" }],
      });
    }
  };

  cityChange = (val) => {
    this.setState({ city: val.id });
  };

  AddData = () => {
    this.setState({
      name: "",
      code: "",
      no_of_checkpoints: "",
      no_of_gaurds: "",
      no_of_members: "",
      no_of_project_staff: "",
      country: "0",
      city: "0",
      state1: "0",
      post_code: "",
      address: "",
      contact_person_name: "",
      contact_person_email: "",
      contact_person_designation: "",
      contact_person_phone: "",
      contact_person_fax: "",
      latitude: "",
      longitude: "",
      geofence_radius: "",
      geocode: "",
      image: null,
    });
    this.props.history.push("/client/project");
  };

  componentWillMount() {
    this.GetCountry();
  }

  checkValidation = async () => {
    if (isEmpty(this.state.name)) {
      toast.error("Please enter project name!");
      return false;
    } else if (isEmpty(this.state.code)) {
      toast.error("Please enter project code!");
      return false;
    } else if (isEmpty(this.state.country) || this.state.country === "0") {
      toast.error("Please select country!");
      return false;
    } else if (isEmpty(this.state.state1) || this.state.state1 === "0") {
      toast.error("Please select state!");
      return false;
    } else if (isEmpty(this.state.city) || this.state.city === "0") {
      toast.error("Please select city!");
      return false;
    } else return true;
  };

  EnterFormElement = (name, type) => {
    if (name === "state") {
      if (!isEmpty(this.state.state1))
        formData.append(name, Number(this.state.state1));
    } else if (!isEmpty(this.state[name])) {
      if (type === "int" || type === "float") {
        formData.append(name, Number(this.state[name]));
      } else {
        formData.append(name, this.state[name]);
      }
    }
    if (type === "image") {
      if (this.state[name] !== null) formData.append("logo", this.state[name]);
    }
  };

  CreateForm = () => {
    this.EnterFormElement("name", "string");
    this.EnterFormElement("code", "string");
    this.EnterFormElement("no_of_checkpoints", "string");
    this.EnterFormElement("no_of_gaurds", "string");
    this.EnterFormElement("no_of_members", "string");
    this.EnterFormElement("no_of_project_staff", "string");
    this.EnterFormElement("country", "int");
    this.EnterFormElement("city", "int");
    this.EnterFormElement("state", "int");
    this.EnterFormElement("post_code", "string");
    this.EnterFormElement("address", "string");
    this.EnterFormElement("contact_person_name", "string");
    this.EnterFormElement("contact_person_email", "string");
    this.EnterFormElement("contact_person_designation", "string");
    this.EnterFormElement("contact_person_phone", "string");
    this.EnterFormElement("contact_person_fax", "string");
    this.EnterFormElement("latitude", "float");
    this.EnterFormElement("longitude", "float");
    this.EnterFormElement("geofence_radius", "string");
    this.EnterFormElement("geocode", "string");
    this.EnterFormElement("image", "image");
    this.EnterFormElement("status", "string");
  };

  async Save() {
    var check = await this.checkValidation();
    if (check) {
      toast.success("Creating project ...");
      formData = new FormData();
      this.CreateForm();
      axios
        .post(`${URL}/project`, formData)
        .then(async (res) => {
          toast.success("Project Saved Successfully!");
          this.AddData();
          this.props.history.push("/client/project");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  UploadFiles(e) {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        this.setState({ imagePreview1: reader.result });
      }
    };
    reader.readAsDataURL(e.target.files[0]);
    this.setState({ avatar: e.target.files[0] });
    this.setState({ image: e.target.files[0] });
  }

  render() {
    const customStyles = {
      control: (base) => ({
        ...base,
        height: 33,
        minHeight: 33,
        border: "1px solid #E4E6EF",
        borderRadius: "3px",
        fontSize: 10.5,
        fontWeight: 400,
        paddingTop: -25,
      }),
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <div className="row">
            <div className="col-sm-6 col-md-8 col-lg-8"></div>
            <div
              className="col-sm-6 col-md-4 col-lg-4"
              style={{ float: "right" }}
            ></div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <Card>
                <CardHeader
                  title="Add Project"
                  icon={
                    <i
                      className="flaticon-employees mr-3 mb-3"
                      style={{ fontSize: "35px", color: "#3F4254" }}
                    ></i>
                  }
                >
                  <CardHeaderToolbar>
                    <Link
                      type="button"
                      className="btn btn-primary"
                      name="add_button"
                      to="/client/project"
                    >
                      <span className="svg-icon menu-icon mr-2">
                        <svg
                          viewBox="0 0 24 24"
                          version="1.1"
                          class="kt-svg-icon"
                        >
                          <g
                            stroke="none"
                            stroke-width="1"
                            fill="none"
                            fill-rule="evenodd"
                          >
                            <rect
                              id="bound"
                              x="0"
                              y="0"
                              width="24"
                              height="24"
                            />
                            <path
                              d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z"
                              id="Combined-Shape"
                              fill="#000000"
                              opacity="0.3"
                            />
                            <path
                              d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z"
                              id="Combined-Shape"
                              fill="#000000"
                            />
                            <rect
                              id="Rectangle-152"
                              fill="#000000"
                              opacity="0.3"
                              x="10"
                              y="9"
                              width="7"
                              height="2"
                              rx="1"
                            />
                            <rect
                              id="Rectangle-152-Copy-2"
                              fill="#000000"
                              opacity="0.3"
                              x="7"
                              y="9"
                              width="2"
                              height="2"
                              rx="1"
                            />
                            <rect
                              id="Rectangle-152-Copy-3"
                              fill="#000000"
                              opacity="0.3"
                              x="7"
                              y="13"
                              width="2"
                              height="2"
                              rx="1"
                            />
                            <rect
                              id="Rectangle-152-Copy"
                              fill="#000000"
                              opacity="0.3"
                              x="10"
                              y="13"
                              width="7"
                              height="2"
                              rx="1"
                            />
                            <rect
                              id="Rectangle-152-Copy-5"
                              fill="#000000"
                              opacity="0.3"
                              x="7"
                              y="17"
                              width="2"
                              height="2"
                              rx="1"
                            />
                            <rect
                              id="Rectangle-152-Copy-4"
                              fill="#000000"
                              opacity="0.3"
                              x="10"
                              y="17"
                              width="7"
                              height="2"
                              rx="1"
                            />
                          </g>
                        </svg>
                      </span>
                      List
                    </Link>
                  </CardHeaderToolbar>
                </CardHeader>
                <CardBody className="row">
                  <div className="col-12">
                    <div className="row">
                      <div className="col-md-9 col-lg-10 col-sm-6"></div>
                      <div className="col-md-3 col-lg-2 col-sm-6 -mt-5">
                        <Form.Group style={{ float: "right" }}>
                          <Form.Label>Status</Form.Label>
                          <select
                            className="form-control form-control-sm"
                            name="status"
                            value={this.state.status}
                            onChange={this.onChange}
                          >
                            <option value="ACTIVE">Active</option>
                            <option value="DEACTIVE">De Active</option>
                          </select>
                        </Form.Group>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-8 col-6">
                    <div className="row">
                      <TextInput
                        label="Name"
                        name="name"
                        width="col-md-6 col-12"
                        CheckKey={this.CheckKey}
                        type="text"
                        placeholder="Enter project name"
                        value={this.state.name}
                        icon="flaticon-email"
                        onChange={this.onChange}
                      />
                      <TextInput
                        label="Code"
                        name="code"
                        width="col-md-6 col-12"
                        CheckKey={this.CheckKey}
                        type="text"
                        placeholder="Enter project code"
                        value={this.state.code}
                        icon="flaticon-email"
                        onChange={this.onChange}
                      />
                      <TextInput
                        label="No. of checkpoints"
                        name="no_of_checkpoints"
                        width="col-md-6 col-12"
                        CheckKey={this.CheckKey}
                        type="number"
                        placeholder="Enter number of checkpoints"
                        value={this.state.no_of_checkpoints}
                        icon="flaticon-email"
                        onChange={this.onChange}
                      />
                      <TextInput
                        label="No. of guards"
                        name="no_of_gaurds"
                        width="col-md-6 col-12"
                        CheckKey={this.CheckKey}
                        type="number"
                        placeholder="Enter number of guards"
                        value={this.state.no_of_gaurds}
                        icon="flaticon-email"
                        onChange={this.onChange}
                      />
                      <TextInput
                        label="No. of staff"
                        name="no_of_project_staff"
                        width="col-md-6 col-12"
                        CheckKey={this.CheckKey}
                        type="number"
                        placeholder="Enter number of staff"
                        value={this.state.no_of_project_staff}
                        icon="flaticon-email"
                        onChange={this.onChange}
                      />
                      <TextInput
                        label="No. of resident / members"
                        name="no_of_members"
                        width="col-md-6 col-12"
                        CheckKey={this.CheckKey}
                        type="number"
                        placeholder="Enter number of resident / members"
                        value={this.state.no_of_members}
                        icon="flaticon-email"
                        onChange={this.onChange}
                      />
                      <Form.Group className="col-md-6 col-12">
                        <Form.Label>Country</Form.Label>
                        <Select
                          styles={customStyles}
                          name="country"
                          options={this.state.allCountry}
                          onChange={this.CountryChange}
                          value={this.state.allCountry.filter(
                            ({ id }) => id === this.state.country
                          )}
                          getOptionLabel={({ name }) => name}
                          getOptionValue={({ id }) => id}
                        />
                      </Form.Group>

                      <Form.Group className="col-md-6 col-12">
                        <Form.Label>State</Form.Label>
                        <Select
                          styles={customStyles}
                          name="state1"
                          options={this.state.allState}
                          onChange={this.StateChange}
                          value={this.state.allState.filter(
                            ({ id }) => id === this.state.state1
                          )}
                          getOptionLabel={({ name }) => name}
                          getOptionValue={({ id }) => id}
                        />
                      </Form.Group>

                      <TextInput
                        label="Address"
                        name="address"
                        width="col-12"
                        CheckKey={this.CheckKey}
                        type="text"
                        placeholder="Enter address"
                        value={this.state.address}
                        icon="flaticon-email"
                        onChange={this.onChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-4 col-6">
                    <div className="row">
                      <Form.Group
                        className="col-12 "
                        style={{ marginTop: "-10px" }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            position: "relative",
                            width: "100%",
                          }}
                        >
                          {this.state.imagePreview1 ? (
                            <p
                              style={{
                                position: "absolute",
                                top: "0%",
                                fontSize: "14px",
                                left: "65%",
                                color: "red",
                              }}
                            >
                              <button
                                onClick={() => {
                                  this.setState({
                                    imagePreview1: "",
                                    avatar: "",
                                  });
                                }}
                                class="btn btn-xs btn-default btn-text-primary btn-hover-danger btn-icon btn-icon-danger "
                                title="Delete"
                                ng-click="Delete(role.srNo)"
                              >
                                <span class="svg-icon svg-icon-md">
                                  <svg viewBox="0 0 24 24" version="1.1">
                                    <g
                                      stroke="none"
                                      stroke-width="1"
                                      fill="none"
                                      fill-rule="evenodd"
                                    >
                                      <rect
                                        x="0"
                                        y="0"
                                        width="24"
                                        height="24"
                                      ></rect>
                                      <path
                                        d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                        fill="#000000"
                                        fill-rule="nonzero"
                                      ></path>
                                      <path
                                        d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                        fill="#000000"
                                        opacity="0.3"
                                      ></path>
                                    </g>
                                  </svg>
                                </span>
                              </button>
                            </p>
                          ) : (
                            ""
                          )}

                          <Avatar
                            alt="res"
                            id="target"
                            style={{ width: "183px", height: "183px" }}
                            src={this.state.imagePreview1}
                            onClick={() => this.fileInput1.click()}
                          />
                        </div>
                        <p
                          onClick={() => this.fileInput1.click()}
                          className="text-center mt-5"
                          style={{ cursor: "pointer" }}
                        >
                          Select an image
                        </p>

                        <input
                          onKeyPress={this.CheckKey}
                          className="form-control form-control-sm d-none"
                          onChange={(e) => this.UploadFiles(e)}
                          type="file"
                          ref={(fileInput) => (this.fileInput1 = fileInput)}
                        ></input>
                      </Form.Group>
                      <Form.Group className="col-12">
                        <Form.Label>City</Form.Label>
                        <Select
                          styles={customStyles}
                          name="city"
                          options={this.state.allCity}
                          onChange={this.cityChange}
                          value={this.state.allCity.filter(
                            ({ id }) => id === this.state.city
                          )}
                          getOptionLabel={({ name }) => name}
                          getOptionValue={({ id }) => id}
                        />
                      </Form.Group>
                      <TextInput
                        label="Postal Code"
                        name="post_code"
                        width="col-12"
                        CheckKey={this.CheckKey}
                        type="text"
                        placeholder="Enter postal code"
                        value={this.state.post_code}
                        icon="flaticon-email"
                        onChange={this.onChange}
                      />
                    </div>
                  </div>

                  <div className="col-12">
                    <Tabs
                      defaultActiveKey="contact_person"
                      transition={false}
                      id="noanim-tab-example"
                    >
                      <Tab eventKey="contact_person" title="Contact Person">
                        <div className="row mt-3">
                          <TextInput
                            label="Name"
                            name="contact_person_name"
                            width="col-md-4 col-12"
                            CheckKey={this.CheckKey}
                            type="text"
                            placeholder="Enter name"
                            value={this.state.contact_person_name}
                            icon="flaticon-email"
                            onChange={this.onChange}
                          />
                          <TextInput
                            label="Email"
                            name="contact_person_email"
                            width="col-md-4 col-12"
                            CheckKey={this.CheckKey}
                            type="text"
                            placeholder="Enter email"
                            value={this.state.contact_person_email}
                            icon="flaticon-email"
                            onChange={this.onChange}
                          />
                          <TextInput
                            label="Designation"
                            name="contact_person_designation"
                            width="col-md-4 col-12"
                            CheckKey={this.CheckKey}
                            type="text"
                            placeholder="Enter designation"
                            value={this.state.contact_person_designation}
                            icon="flaticon-email"
                            onChange={this.onChange}
                          />
                          <TextInput
                            label="Phone Number"
                            name="contact_person_phone"
                            width="col-md-4 col-12"
                            CheckKey={this.CheckKey}
                            type="text"
                            placeholder="Enter phone number"
                            value={this.state.contact_person_phone}
                            icon="flaticon-email"
                            onChange={this.onChange}
                          />
                          <TextInput
                            label="Fax"
                            name="contact_person_fax"
                            width="col-md-4 col-12"
                            CheckKey={this.CheckKey}
                            type="text"
                            placeholder="Enter fax number"
                            value={this.state.contact_person_fax}
                            icon="flaticon-email"
                            onChange={this.onChange}
                          />
                        </div>
                      </Tab>

                      <Tab eventKey="location" title="Location">
                        <div className="row mt-3">
                          <TextInput
                            label="Longitude"
                            name="longitude"
                            width="col-md-6 col-12"
                            CheckKey={this.CheckKey}
                            type="text"
                            placeholder="Enter project longitude"
                            value={this.state.longitude}
                            icon="flaticon-email"
                            onChange={this.onChange}
                          />
                          <TextInput
                            label="Latitude"
                            name="latitude"
                            width="col-md-6 col-12"
                            CheckKey={this.CheckKey}
                            type="text"
                            placeholder="Enter project latitude"
                            value={this.state.latitude}
                            icon="flaticon-email"
                            onChange={this.onChange}
                          />
                          <TextInput
                            label="Geofence Radius"
                            name="geofence_radius"
                            width="col-md-6 col-12"
                            CheckKey={this.CheckKey}
                            type="text"
                            placeholder="Enter project geofence radius"
                            value={this.state.geofence_radius}
                            icon="flaticon-email"
                            onChange={this.onChange}
                          />
                          <TextInput
                            label="Geocode"
                            name="geocode"
                            width="col-md-6 col-12"
                            CheckKey={this.CheckKey}
                            type="text"
                            placeholder="Enter project geocode"
                            value={this.state.geocode}
                            icon="flaticon-email"
                            onChange={this.onChange}
                          />
                        </div>
                      </Tab>
                    </Tabs>
                  </div>
                </CardBody>
                <div>
                  <button
                    className="btn btn-primary   mr-4 mb-2"
                    onClick={() => {
                      this.Save();
                    }}
                    style={{ float: "right" }}
                  >
                    <span className="svg-icon menu-icon mr-2">
                      <svg
                        viewBox="0 0 24 24"
                        version="1.1"
                        class="kt-svg-icon"
                      >
                        <g
                          stroke="none"
                          stroke-width="1"
                          fill="none"
                          fill-rule="evenodd"
                        >
                          <polygon id="Shape" points="0 0 24 0 24 24 0 24" />
                          <path
                            d="M17,4 L6,4 C4.79111111,4 4,4.7 4,6 L4,18 C4,19.3 4.79111111,20 6,20 L18,20 C19.2,20 20,19.3 20,18 L20,7.20710678 C20,7.07449854 19.9473216,6.94732158 19.8535534,6.85355339 L17,4 Z M17,11 L7,11 L7,4 L17,4 L17,11 Z"
                            id="Shape"
                            fill="#000000"
                            fill-rule="nonzero"
                          />
                          <rect
                            id="Rectangle-16"
                            fill="#000000"
                            opacity="0.3"
                            x="12"
                            y="4"
                            width="3"
                            height="5"
                            rx="0.5"
                          />
                        </g>
                      </svg>
                    </span>
                    Create
                  </button>

                  <button
                    className="btn btn-danger mr-3 mb-2"
                    onClick={() => this.AddData()}
                    name="cancel"
                    style={{ float: "right" }}
                  >
                    <span className="svg-icon menu-icon mr-2">
                      <svg
                        viewBox="0 0 24 24"
                        version="1.1"
                        class="kt-svg-icon"
                      >
                        <g
                          stroke="none"
                          stroke-width="1"
                          fill="none"
                          fill-rule="evenodd"
                        >
                          <g
                            id="Group"
                            transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)"
                            fill="#000000"
                          >
                            <rect
                              id="Rectangle-185"
                              x="0"
                              y="7"
                              width="16"
                              height="2"
                              rx="1"
                            />
                            <rect
                              id="Rectangle-185-Copy"
                              opacity="0.3"
                              transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) "
                              x="0"
                              y="7"
                              width="16"
                              height="2"
                              rx="1"
                            />
                          </g>
                        </g>
                      </svg>
                    </span>
                    Cancel
                  </button>
                </div>
              </Card>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(AddProject);
