import { toast } from "react-toastify";
import moment from "moment";

export default function setupAxios(axios, store) {
  axios.interceptors.request.use(
    (config) => {
      const {
        auth: { authToken },
      } = store.getState();

      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
      }

      return config;
    },
    (err) => Promise.reject(err)
  );

  axios.interceptors.response.use(
    (response) =>
      new Promise(async (resolve, reject) => {
        // console.log(
        //   "axios-response",
        //   response.status,
        //   JSON.stringify(response.data)
        // );
        return resolve(response);
      }),
    (error) =>
      new Promise(async (resolve, reject) => {
        if (error.response) {
          console.log(error.response);
          console.log("axios-response-error", JSON.stringify(error.response));
        } else {
          console.log("axios-response-error", error);
        }

        if (error.response) {
          if (error.response.status == 401) {
            toast.error("Unauthorized access.\nPlease login again.");
          } else if (error.response.status == 404) {
            toast.error("Record not found!");
          } else if (error.response.status == 400) {
            toast.error(error.response.msg);
          } else if (error.response.status == 500) {
            toast.error("Internal server error");
          } else if (error.response.status == 403) {
            toast.error("Unable to process required request.");
          } else if (error.response.status == 413) {
            toast.error("Entity too large.");
          } else if (error.response.status == 422) {
            error.response.data.errors.map((error, index) => {
              if (error.rule === "required") {
                toast.error(
                  error.field.charAt(0).toUpperCase() +
                    error.field.slice(1) +
                    " is required !"
                );
              } else if (error.rule === "enum") {
                let choices = "";
                error.args.choices.map((c, index) => {
                  if (index > 0) choices += "," + c;
                  else choices += c;
                });
                toast.error(
                  error.field.charAt(0).toUpperCase() +
                    error.field.slice(1) +
                    " must be in [ " +
                    choices +
                    " ] !"
                );
              } else if (error.rule === "file.extname") {
                toast.error(
                  error.field.charAt(0).toUpperCase() +
                    error.field.slice(1) +
                    ": " +
                    error.message +
                    " !"
                );
              } else if (error.rule === "maxLength") {
                toast.error(
                  error.field.charAt(0).toUpperCase() +
                    error.field.slice(1) +
                    " length must be less than " +
                    error.args.maxLength +
                    " !"
                );
              } else if (error.rule === "minLength") {
                toast.error(
                  error.field.charAt(0).toUpperCase() +
                    error.field.slice(1) +
                    " length must be greater than " +
                    error.args.minLength +
                    " !"
                );
              } else if (error.rule === "unique") {
                toast.error(
                  error.field.charAt(0).toUpperCase() +
                    error.field.slice(1) +
                    " is already assigned please try another value !"
                );
              } else if (error.rule === "regex") {
                toast.error(error.message);
              } else if (error.rule === "confirmed") {
                if (error.field === "password_confirmation") {
                  toast.error("Confirmed password must be same as password !");
                }
              } else if (error.rule === "range") {
                toast.error(
                  error.field.charAt(0).toUpperCase() +
                    error.field.slice(1) +
                    " must be in between " +
                    error.args.start +
                    " and " +
                    error.args.stop +
                    " !"
                );
              } else if (error.rule === "number") {
                toast.error(
                  error.field.charAt(0).toUpperCase() +
                    error.field.slice(1) +
                    " must be a number !"
                );
              } else if (error.rule === "string") {
                toast.error(
                  error.field.charAt(0).toUpperCase() +
                    error.field.slice(1) +
                    " must be a string !"
                );
              } else if (error.rule === "boolean") {
                toast.error(
                  error.field.charAt(0).toUpperCase() +
                    error.field.slice(1) +
                    " must be a boolean !"
                );
              } else if (error.rule === "array") {
                toast.error(
                  error.field.charAt(0).toUpperCase() +
                    error.field.slice(1) +
                    " must be an array !"
                );
              } else if (error.rule === "date.format") {
                toast.error(
                  "Error at " +
                    error.field.charAt(0).toUpperCase() +
                    error.field.slice(1) +
                    " " +
                    error.message
                );
              } else if (error.rule === "after") {
                toast.error(
                  "At " +
                    error.field.charAt(0).toUpperCase() +
                    error.field.slice(1) +
                    " you must select a date that comes after " +
                    moment(error.args.after).format("YYYY-MM-DD") +
                    " !"
                );
              } else if (error.rule === "before") {
                toast.error(
                  "At " +
                    error.field.charAt(0).toUpperCase() +
                    error.field.slice(1) +
                    " you must select a date that comes before " +
                    moment(error.args.after).format("YYYY-MM-DD") +
                    " !"
                );
              } else if (error.rule === "requiredWhen") {
                let choices = "";
                error.args.values.map((c, index) => {
                  if (index > 0) choices += "," + c;
                  else choices += c;
                });
                toast.error(
                  error.field.charAt(0).toUpperCase() +
                    error.field.slice(1) +
                    " is required when " +
                    error.args.otherField +
                    " is in [" +
                    choices +
                    " ] !"
                );
              }
            });
          } else {
            if (error.response.data?.message) {
              toast.error(
                error.response.status + ": " + error.response.data.message
              );
            }
          }

          return reject({
            status: error.response.status,
            response: error.response.data,
          });
        }
        reject(error);
      })
  );
}
