import { createSlice } from "@reduxjs/toolkit";

const initialCustomersState = {
  listLoading: false,
  projects: null,
  totalProjects: 0
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const projectsSlice = createSlice({
  name: "project",
  initialState: initialCustomersState,
  reducers: {

    projectsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.projects = entities;
      state.totalProjects = totalCount;
    }
  }
});
