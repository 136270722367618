import { createSlice } from "@reduxjs/toolkit";

const initialCustomersState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  customerForEdit: undefined,
  lastError: null,
  roles: null,
  totalRoles: 0
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const rolesSlice = createSlice({
  name: "roles",
  initialState: initialCustomersState,
  reducers: {

    rolesFetched: (state, action) => {
      // console.log("states before", state);
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.roles = entities;
      state.totalRoles = totalCount;
    },
  }
});
